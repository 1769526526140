/* ADD CLIENT MODAL */
.usersControlModal .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.usersControlModal .modal-content{
	padding: 							90px 100px;
}

.usersControlModal .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

.usersControlModal.darkMode .closeModal{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.usersControlModal .modal-title .modalTitle {
	font-size: 							23px;
}

.usersControlModal .modal-title span {
	font-size: 							14px;
}

.usersControlModal .modal-body {
	padding: 							30px 0 0 0;
}

.usersControlModal .modal-body .nav-tabs {
	margin-bottom: 						20px;
}

.usersControlModal.darkMode .modal-body .nav-tabs .nav-link {
	background-color: 					#0C1924;
	color: 								white;
}

.usersControlModal.darkMode .modal-body .nav-tabs .nav-link.active {
	background-color: 					#243D55;
}

.usersControlModal .modal-body .entitiesTablesSection {
	max-height: 						400px;
	overflow-y: 						auto;
	margin-bottom: 						30px;
}

.usersControlModal .modal-body .usersTable {
	margin-bottom: 						30px;
}

.usersControlModal .modal-body .clientTab .usersTable {
	max-height: 						470px;
	overflow-y: 						auto;
}

/* width */
.usersControlModal .modal-body .usersTable::-webkit-scrollbar,
.usersControlModal .modal-body .entitiesTablesSection::-webkit-scrollbar {
	width: 								3px;
}

 /* Track */
.usersControlModal .modal-body .usersTable::-webkit-scrollbar-track,
.usersControlModal .modal-body .entitiesTablesSection::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}

/* Handle */
.usersControlModal .modal-body .usersTable::-webkit-scrollbar-thumb,
.usersControlModal .modal-body .entitiesTablesSection::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.usersControlModal.darkMode .modal-body .usersTable::-webkit-scrollbar-thumb,
.usersControlModal.darkMode .modal-body .entitiesTablesSection::-webkit-scrollbar-thumb {
	background: 						white;
}

.usersControlModal .modal-body .clientNameHeader {
	font-size: 							16px;
	font-weight: 						700;
	padding: 							15px;
	background-color: 					white;
}

.usersControlModal.darkMode .modal-body .clientNameHeader {
	background-color: 					#202B34;
}

.usersControlModal .modal-body .noUsers {
	display: 							flex;
	justify-content: 					center;
	padding: 							20px;
	font-size: 							12px;
	font-weight: 						400;
}

.usersControlModal .modal-body .tableRow {
	display: 							grid;
	grid-template-columns: 				repeat(4, 5fr) 1fr;
	padding: 							15px;
	align-items: 						center;
	column-gap: 						20px;
}

.usersControlModal .modal-body .tableTitles {
	background-color: 					rgba(4, 26, 57, 0.05);
}

.usersControlModal.darkMode .modal-body .tableTitles {
	background-color: 					#0C1924;
}

.usersControlModal.darkMode .modal-body:has(.noUsers) .tableTitles span {
	opacity: 							0.5;
}

.usersControlModal .modal-body .tableTitles span {
	font-weight: 						700;
	font-size: 							12px;
}

.usersControlModal .modal-body .userRow span,
.usersControlModal .modal-body .userRow button {
	font-size: 							12px;
	text-wrap: 							nowrap;
	overflow: 							hidden;
	text-overflow: 						ellipsis;
}

.usersControlModal .modal-body .userRow span:nth-of-type(2) {
	opacity: 							0.5;
}

.usersControlModal .modal-body .userRow img {
	justify-self: 						center;
	object-fit: 						contain;
	width: 								15px;
	height: 							15px;
	cursor: 							pointer;
}

.usersControlModal .modal-body .actions{
	display: 							flex;
	justify-content: 					flex-end;
	align-items: 						center;
	gap: 								25px;
}

.usersControlModal .modal-body .actions > button:disabled {
	background: 						unset;
	color: 								rgba(195, 195, 195, 1);
	-webkit-text-fill-color: 			rgba(195, 195, 195, 1);
	background-color: 					rgba(235, 235, 235, 1);
}

.usersControlModal.darkMode .modal-body .actions > button:disabled {
	color: 								rgba(135, 135, 135, 1);
	-webkit-text-fill-color: 			rgba(135, 135, 135, 1);
	background-color: 					#27343F;
}

@media (max-width: 991px){
	.usersControlModal .modal-content{
		border-radius: 						35px;
		padding: 							60px 30px;
	}
	
	.usersControlModal .modal-body{
		padding: 							0;
	}
	
	.usersControlModal .modal-body .closeModal{
		top: 								15px;
		right: 								15px;
	}

	.usersControlModal .modal-body .tableTitles{
		display: 							none;
	}

	.usersControlModal .modal-body .userRow {
		grid-template-columns: 				5fr 1fr;
		row-gap: 							10px;
		padding: 							0;
		margin-bottom: 						20px;
	}

	.usersControlModal .modal-body .userRow #userUsername {
		grid-column: 						1 / -1;
		padding: 							10px;
		font-weight: 						700;
		background-color: 					#0C1924;
	}
	
	.usersControlModal .modal-body .userRow #userRole {
		grid-column: 						1 / -1;
		display: 							flex;
		flex-direction: 					column;
		opacity: 							1;
	}
	
	.usersControlModal .modal-body .userRow #userRole::after {
		content: 							"Role";
		opacity: 							0.5;
	}
	
	.usersControlModal .modal-body .userRow .inputContainer {
		grid-column: 						1 / -1;
		display: 							flex;
		flex-direction: 					column;
	}

	.usersControlModal .modal-body .userRow .inputContainer::after {
		content: 							"New username";
		font-size: 							12px;
		opacity: 							0.5;
	}

	/* .usersControlModal .modal-body .userRow > button {
		max-width: 							150px;
	} */

	.usersControlModal .modal-body .actions {
		flex-direction: 					column;
		gap: 								10px;
		justify-content: 					center;
	}

	.usersControlModal .modal-body .actions .btnBgBlueTextGradient {
		order: 								0;
		width: 								100%;
	}
	
	.usersControlModal .modal-body .actions .btnBgWhiteTextBlack {
		order: 								1;
		width: 								100%;
	}
}