/* INDUSTRY CONFIGURATION */
.industryConfiguration {
	margin-bottom: 						130px;
}

.industryConfiguration .titleAndBack {
	height: 							40px;
	display: 							flex;
	gap: 								15px;
	align-items: 						center;
	margin-bottom: 						50px;
}

.industryConfiguration .titleAndBack .goBack{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	transform: 							rotate(180deg);
	cursor: 							pointer;
}

.industryConfiguration .titleAndBack h4{
	font-weight:						700;
}

.industryConfiguration .titleAndBack .editImg{
	width: 								16px;
	height: 							16px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.industryConfiguration .titleHeader{
	font-size: 							16px;
	font-weight: 						700;
	padding: 							15px 20px;
	background-color: 					#FFFFFF;
	margin-bottom: 						15px;
}

.industryConfiguration .titleHeader:nth-of-type(2){
	margin-top: 						50px;
}

.industryConfiguration .tableTitles {
	display: 							flex;
	flex-flow: 							row nowrap;
	gap: 								10px;
	padding: 							20px;
	background-color: 					rgba(4, 26, 57, 0.03);
}

.industryConfiguration .tableTitles.darkMode {
	background-color: 					#27343F;
}

.industryConfiguration .tableDocuments{
	display: 							flex;
	flex-flow: 							row nowrap;
	gap: 								10px;
	padding: 							15px 20px;
	border-bottom: 						1px solid rgba(0, 0, 0, 0.031);
}

.industryConfiguration .tableTitles .inputContainer,
.industryConfiguration .tableDocuments .inputContainer{
	flex: 								1 0 0;
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	gap: 								10px;
	justify-content: 					flex-start;
}

.industryConfiguration .tableDocuments.darkMode .inputContainer > input,
.industryConfiguration .tableDocuments.darkMode .inputContainer .dropdown-toggle {
	background: 						#131E29;
    color: 								white;
}

.industryConfiguration .tableTitles .inputContainer:nth-of-type(2),
.industryConfiguration .tableDocuments .inputContainer:nth-of-type(2){
	flex: 								0 0 10%;
	max-width: 							10%;
}

.industryConfiguration .tableTitles .inputContainer:nth-of-type(3),
.industryConfiguration .tableDocuments .inputContainer:nth-of-type(3){
	flex: 								0 0 15%;
	max-width: 							15%;
}

.industryConfiguration .tableTitles .inputContainer:nth-of-type(4),
.industryConfiguration .tableDocuments .inputContainer:nth-of-type(4){
	flex: 								0 0 10%;
	max-width: 							10%;
}

.industryConfiguration .tableTitles .inputContainer:nth-of-type(7),
.industryConfiguration .tableDocuments .inputContainer:nth-of-type(7){
	flex: 								0 0 115px;
	max-width: 							115px;
}

.industryConfiguration .tableTitles .inputContainer > img,
.industryConfiguration .tableDocuments .inputContainer > img {
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.industryConfiguration .tableTitles .inputContainer > .label{
	width: 								fit-content;
	margin: 							0;
}

.industryConfiguration .tableDocuments .inputContainer > .labelHolder{
	height: 							fit-content;
	font-size: 							10px;
	opacity: 							0.5;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
}

.industryConfiguration .tableDocuments .inputContainer .toggleContainer {
	position: 							relative;
	width: 								90px;
	border-radius: 						100px;
	background-color: 					rgba(4, 26, 57, 0.302);
	padding: 							5px;
	cursor: 							pointer;
	overflow: 							hidden;
	transition: 						all 0.3s ease-in-out;
}

.industryConfiguration .tableDocuments.darkMode .inputContainer .toggleContainer {
	background-color: 					#181E24;
}

.industryConfiguration .tableDocuments .inputContainer .toggleContainer.active {
	background-color: 					var(--green);
}

.industryConfiguration .tableDocuments .inputContainer .toggleContainer .toggle {
	width: 								20px;
	height: 							20px;
	border-radius: 						100px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
}

.industryConfiguration .tableDocuments .inputContainer .toggleContainer.active .toggle {
	transform: 							translateX(300%);
}

.industryConfiguration .tableDocuments .inputContainer .toggleContainer .toggle::before {
	content: 							"ON";
	font-size: 							12px;
	color: 								#FFFFFF;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	right: 								300%;
}

.industryConfiguration .tableDocuments .inputContainer .toggleContainer .toggle::after {
	content: 							"OFF";
	font-size: 							12px;
	color: 								#FFFFFF;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	left: 								280%;
}

.industryConfiguration > .btnBgBlueTextGradient{
	padding: 							5px 10px;
	margin: 							15px 0 15px 20px;
}

.industryConfiguration > .btnBgBlueTextGradient::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images//add.svg") center center no-repeat;
	background-size: 					20px 20px;
}

.industryConfiguration .actions{
	display: 							flex;
	gap: 								15px;
	justify-content: 					flex-end;
}

.industryConfiguration .actions .btnBgBlueTextGradient:first-of-type{
	background-color: 					transparent;
	-webkit-text-fill-color: 			#000000;
}

.industryConfiguration .actions.darkMode .btnBgBlueTextGradient:first-of-type{
	-webkit-text-fill-color: 			white;
}

#periodicityTooltip .tooltip-inner{
	font-size: 							12px;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-weight: 						500;
	padding: 							10px 20px;
}

#periodicityTooltip .tooltip-arrow::before{
	border-top: 						6px solid #FFFFFF;
}

@media (max-width: 992px){
	/* INDUSTRY CONFIGURATION */
	.industryConfiguration {
		margin-bottom: 						50px;
	}

	.industryConfiguration .titleAndBack {
		margin-bottom: 						25px;
		padding: 							0 20px;
	}

	.industryConfiguration .titleHeader,
	.industryConfiguration .tableTitles,
	.industryConfiguration .tableDocuments{
		display: 							none;
	}

	.industryConfiguration .titleHeaderMobile{
		padding: 							0 20px;
		background-color: 					transparent;
		margin-bottom: 						20px;
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
	}

	.industryConfiguration .titleHeaderMobile > .btnBgBlueTextGradient{
		padding: 							5px 10px;
	}

	.industryConfiguration .titleHeaderMobile > .btnBgBlueTextGradient::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images//add.svg") center center no-repeat;
		background-size: 					20px 20px;
	}

	.industryConfiguration .accordion-item{
		border: 							unset;
		margin-bottom: 						20px;
	}

	.industryConfiguration .accordion-item .accordion-button{
		font-size: 							12px;
		font-weight: 						700;
		background-color: 					rgba(4, 26, 57, 0.1);
		border-radius: 						0;
	}

	.industryConfiguration .accordion-item .accordion-button::after{
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px 15px;
		transform: 							rotate(90deg);
	}

	.industryConfiguration .accordion-item .accordion-button:not(.collapsed):after{
		transform: 							rotate(270deg);
	}

	.industryConfiguration .accordion-item .accordion-button:focus{
		box-shadow: 						unset;
	}

	.industryConfiguration .accordion-body{
		padding: 							10px 15px;
		background-color: 					var(--grey);
	}

	.industryConfiguration .accordion-body .inputContainer{
		flex-flow:							row wrap;
		align-items: 						center;
		justify-content: 					flex-start;
		margin-top: 						20px;
	}

	.industryConfiguration .accordion-body .inputContainer .labelHolder{
		height: 							fit-content;
		font-size: 							10px;
		opacity: 							0.5;
		position: 							absolute;
		bottom: 							10px;
		right: 								10px;
	}
	
	.industryConfiguration .accordion-body .inputContainer .toggleContainer {
		position: 							relative;
		width: 								90px;
		border-radius: 						100px;
		background-color: 					rgba(4, 26, 57, 0.302);
		padding: 							5px;
		cursor: 							pointer;
		overflow: 							hidden;
		transition: 						all 0.3s ease-in-out;
	}
	
	.industryConfiguration .accordion-body .inputContainer .toggleContainer.active {
		background-color: 					var(--green);
	}
	
	.industryConfiguration .accordion-body .inputContainer .toggleContainer .toggle {
		width: 								20px;
		height: 							20px;
		border-radius: 						100px;
		background-color: 					#FFFFFF;
		position: 							relative;
		transition: 						all 0.3s ease-in-out;
	}
	
	.industryConfiguration .accordion-body .inputContainer .toggleContainer.active .toggle {
		transform: 							translateX(300%);
	}
	
	.industryConfiguration .accordion-body .inputContainer .toggleContainer .toggle::before {
		content: 							"ON";
		font-size: 							12px;
		color: 								#FFFFFF;
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		margin: 							auto 0;
		right: 								300%;
	}
	
	.industryConfiguration .accordion-body .inputContainer .toggleContainer .toggle::after {
		content: 							"OFF";
		font-size: 							12px;
		color: 								#FFFFFF;
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		margin: 							auto 0;
		left: 								280%;
	}

	.industryConfiguration .accordion-body .inputContainer > img{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		margin-left: 						auto;
	}

	.industryConfiguration > .btnBgBlueTextGradient{
		display: 							none;
	}

	.industryConfiguration .actions{
		justify-content: 					center;
		flex-flow: 							row wrap;
	}

	.industryConfiguration .actions .btnBgBlueTextGradient{
		width: 								100%;
	}

	.industryConfiguration .actions .btnBgBlueTextGradient:first-of-type{
		order: 								2
	}

	.industryConfiguration .actions .btnBgBlueTextGradient:last-of-type{
		order: 								1;
	}

	#periodicityTooltip .tooltip-inner{
		font-size: 							12px;
		background-color: 					#FFFFFF;
		color: 								#000000;
		font-weight: 						500;
		padding: 							10px 20px;
	}

	#periodicityTooltip .tooltip-arrow::before{
		border-top: 						6px solid #FFFFFF;
	}
}