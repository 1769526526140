.suppliersDasboardTable {
    display:                        flex;
    flex-direction:                 column;
    gap:                            10px;
    margin:                         20px 0 100px;
}

.suppliersDasboardTable .searchContainer {
    min-width:                      140px;
    width:                          25%;
    align-self:                     flex-end;
}

.suppliersDasboardTableHeader,
.supplierContainer {
    display:                        grid;
    grid-template-columns:          repeat(2, 2fr) 1fr 1.5fr 50px;
}

.suppliersDasboardTableHeader {
    background:                     white;
}

.darkMode .suppliersDasboardTableHeader {
    background:                     rgb(19, 30, 41);
}

.suppliersDasboardTableHeader .headerContainer {
    display:                        flex;
    align-items:                    center;
    gap:                            5px;
    padding:                        20px;
}

.suppliersDasboardTableHeader .headerContainer > span {
    font-size:                      12px;
    font-weight:                    700;
}

.suppliersDasboardTableHeader .headerContainer img {
    cursor:                         pointer;
    width:                          15px;
    height:                         15px;
}

.supplierContainer {
    align-items:                    center;
    border-bottom:                  1px solid rgba(0, 0, 0, 0.05);
}

.darkMode .supplierContainer {
    border-bottom:                  1px solid #27343F;
}

.supplierContainer #supplierName {
    cursor:                         pointer;
}

.supplierContainer > span,
.supplierContainer #supplierRating {
    font-size:                      12px;
    font-weight:                    500;
    padding:                        15px 20px;
}

.supplierContainer #supplierRating > span {
    font-size:                      12px;
    font-weight:                    500;
    opacity:                        0.5;
}

.supplierContainer #supplierRating {
    display:                        flex;
}

.supplierContainer #supplierIndustries {
    opacity:                        0.5;
}

.darkMode .supplierContainer .reviewed {
    filter:                         invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.suppliersDasboardTable .tableCount {
    display:                            flex;
	justify-content: 					flex-end;
	margin-bottom: 						30px;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin-right: 						20px;
}

.suppliersDasboardTable .dashboardTablePagination {
    display:                            flex;
	justify-content: 					flex-end;
}

.suppliersDasboardTable .dashboardTablePagination nav {
    padding:                            20px 0 10px;
}

.darkMode .suppliersDasboardTable .dashboardTablePagination nav ul li button{
	color: 								white;
	opacity: 							1;
}

@media (width <= 991px) {
    .suppliersDasboardTableHeader,
    .supplierContainer .reviewed {
        display:                    none;
    }

    .supplierContainer {
        display:                    grid;
        grid-template-columns:      3fr 1fr;
        row-gap:                    20px;
        column-gap:                 5px;
        padding:                    20px;
    }

    .supplierContainer > span,
    .supplierContainer #supplierRating {
        padding:                    0;
    }

    .supplierContainer #supplierName {
        grid-column:                1 / -1;
        font-size:                  16px;
    }

    .supplierContainer #supplierIndustries {
        display:                    flex;
        flex-direction:             column;
        grid-column:                1 / 2;
        grid-row:                   2 / 3;
        opacity:                    1;
        font-weight:                400;
    }
    
    .supplierContainer #supplierIndustries::after {
        content:                    "Industry";
        opacity:                    0.4;
        font-size:                  10px;
        font-weight:                700;
    }
    
    .supplierContainer #supplierRating {
        position:                   relative;
        grid-column:                2 / 3;
        grid-row:                   2 / 3;
        justify-self:               end;
    }
    
    .supplierContainer #supplierRating::after {
        content:                    "Rating";
        position:                   absolute;
        top:                        100%;
        left:                       0;
        opacity:                    0.4;
        font-size:                  10px;
        font-weight:                700;
    }
    
    .supplierContainer #supplierRating > span {
        opacity:                    1;
        font-weight:                400;
    }
    
    .supplierContainer #supplierLocation {
        display:                    flex;
        flex-direction:             column;
        grid-column:                1 / 2;
        grid-row:                   3 / 4;
        font-weight:                400;
    }
    
    .supplierContainer #supplierLocation::after {
        content:                    "Location";
        opacity:                    0.4;
        font-size:                  10px;
        font-weight:                700;
    }
}
