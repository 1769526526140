/* GLOBAL */
.documentCompliance {
	margin-bottom: 					50px;
}

/* TABLE HEADER */
.documentCompliance .tableHeader {
	display: 						flex;
    align-items: 					center;
    background: 					#FFFFFF;
    padding: 						10px 25px;
    gap: 							10px;
}

.darkMode .documentCompliance .tableHeader {
    background: 					#131E29;
}

.documentCompliance .tableHeader:nth-of-type(3) {
	margin-top: 					50px;
}

.documentCompliance .tableHeader .title {
	font-weight: 					700;
}

.documentCompliance .tableHeader .labels {
	flex: 							1 0 0;
	display: 						flex;
	justify-content: 				flex-end;
	gap: 							10px;
}

.documentCompliance .tableHeader .labels .withDot {
	display: 						flex;
    align-items: 					center;
    font-size: 						12px;
    font-weight: 					500;
    color: 							rgba(0, 0, 0, 0.5);
}

.darkMode .documentCompliance .tableHeader .labels .withDot {
    color: 							white;
}

.documentCompliance .tableHeader .labels .withDot::before {
	content:						"";
    width:							10px;
    height:							10px;
    border-radius:					50%;
    margin-right:					5px;
}

.documentCompliance .tableHeader .labels .withDot.green::before {
	background-color: 				#08D1A1;
}

.documentCompliance .tableHeader .labels .withDot.white::before {
	border: 						2px solid transparent;
	background: 					linear-gradient(#FFFFFF, #FFFFFF) padding-box, var(--gradient) border-box;
}

.documentCompliance .tableHeader .labels .withDot.red::before {
	background-color: 				#FF316F;
}

.documentCompliance .tableHeader .labels .withDot.grey::before {
	background-color: 				#E1E1E1;
}

.documentCompliance .tableHeader .labels .withDot.darkYellow::before {
	background-color: 				rgba(255, 207, 82);
}

.documentCompliance .tableHeader .labels .withDot.yellow::before {
	background-color: 				rgba(255, 227, 154);
}

.documentCompliance .tableHeader .clientFilterIcon {
	width: 							20px;
	height: 						20px;
	object-fit: 					contain;
	margin-left: 					auto;
}

.documentCompliance .tableHeader .clientSelector {
	width: 							15%;
}

.documentCompliance .tableHeader .yearButton {
	width: 							fit-content;
}

.darkMode .documentCompliance .tableHeader .yearButton .dropdown-item,
.darkMode .documentCompliance .tableHeader .clientSelector .dropdown-item{
	background: 					#27343F;
	color: 							white;
}

.documentCompliance .tableHeader .yearButton .dropdown-toggle::after {
	background: 					url("../../images/sort.svg") center center no-repeat;
	transform: 						unset;
}

/* TABLE INFORMATION */
.documentCompliance .tableInformation {
	display: 						flex;
	flex-flow: 						row nowrap;
}

.documentCompliance .tableInformation .tableLeftSide {
	flex: 							0 0 35%;
	max-width: 						35%;
}

.documentCompliance .tableInformation .tableLeftSide .tableTitle,
.documentCompliance .tableInformation .tableLeftSide .tableFooter {
	display: 						flex;
	align-items: 					center;
	flex-flow: 						row nowrap;
	gap: 							35px;
	background-color: 				rgba(4, 26, 57, 0.05);
	padding: 						11px 20px;
}

.darkMode .documentCompliance .tableInformation .tableLeftSide .tableTitle,
.darkMode .documentCompliance .tableInformation .tableLeftSide .tableFooter {
	background-color: 				#27343F;
}

.documentCompliance .tableInformation .tableLeftSide .tableTitle .documentNumber {
	width: 							15px;
}

.documentCompliance .tableInformation .tableLeftSide .tableTitle .documentName,
.documentCompliance .tableInformation .tableLeftSide .tableFooter .footerTitle {
	font-size: 						12px;
    font-weight: 					700;
}

.documentCompliance .tableInformation .tableLeftSide .tableFooter {
	padding-right: 					10px;
}

.documentCompliance .tableInformation .tableLeftSide .tableFooter .footerTitle {
	margin-left: 					auto;
}

.documentCompliance .tableInformation .tableLeftSide .tableRow {
	display: 						flex;
	align-items: 					center;
	gap: 							35px;
	padding: 						11px 20px;
}

.documentCompliance .tableInformation .tableLeftSide .tableRow:nth-of-type(2n+1) {
	background-color:				rgba(4, 26, 57, 0.03);
}

.darkMode .documentCompliance .tableInformation .tableLeftSide .tableRow:nth-of-type(2n+1) {
	background-color:				#27343F;
}

.documentCompliance .tableInformation .tableLeftSide .tableRow .documentNumber {
	width: 							15px;
	font-size: 						12px;
    font-weight: 					400;
    opacity: 						0.4;
}

.documentCompliance .tableInformation .tableLeftSide .tableRow .documentName {
	font-size: 						12px;
    font-weight: 					500;
    white-space: 					nowrap;
    overflow: 						hidden;
    text-overflow: 					ellipsis;
	cursor: 						help;
}

.documentCompliance .tableInformation .tableRightSide {
	flex: 							0 0 65%;
	max-width: 						65%;
	overflow-x: 					auto;
	padding-bottom: 				5px;
	background-image: 				linear-gradient(to right, var(--grey), var(--grey)), 
									linear-gradient(to right, var(--grey), var(--grey)), 
									linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)), 
									linear-gradient(to left, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0));
    background-position: 			left center, right center, left center, right center;
    background-repeat: 				no-repeat;
    background-size: 				40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment: 			local, local, scroll, scroll;
}

.darkMode .documentCompliance .tableInformation .tableRightSide {
	background-image: 				linear-gradient(to right, rgba(39, 52, 63, 0.15), rgba(39, 52, 63, 0.15)), 
									linear-gradient(to right, rgba(39, 52, 63, 0.15), rgba(39, 52, 63, 0.15)), 
									linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)), 
									linear-gradient(to left, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0));
}

/* width */
.documentCompliance .tableInformation .tableRightSide::-webkit-scrollbar {
	width: 							10px;
	height: 						10px;
}
  
 /* Track */
.documentCompliance .tableInformation .tableRightSide::-webkit-scrollbar-track {
	background: 					rgba(0, 0, 0, 0.05);
}

/* Handle */
.documentCompliance .tableInformation .tableRightSide::-webkit-scrollbar-thumb {
	border-radius: 					10px;
	background-color: 				var(--darkBlue);
}

.darkMode .documentCompliance .tableInformation .tableRightSide::-webkit-scrollbar-thumb {
	background-color: 				white;
}

.documentCompliance .tableInformation .tableRightSide .tableTitle,
.documentCompliance .tableInformation .tableRightSide .tableFooter {
	display: 						flex;
	align-items: 					center;
	flex-flow: 						row nowrap;
}

.documentCompliance .tableInformation .tableRightSide .tableTitle .documentMonth,
.documentCompliance .tableInformation .tableRightSide .tableFooter .monthPercent {
	flex: 							0 0 10%;
	max-width: 						10%;
	text-align: 					center;
	font-size: 						12px;
    font-weight: 					700;
	background: 					rgba(4, 26, 57, 0.05);
	padding: 						10.5px 20px;
}

.darkMode .documentCompliance .tableInformation .tableRightSide .tableTitle .documentMonth,
.darkMode .documentCompliance .tableInformation .tableRightSide .tableFooter .monthPercent {
	background: 					#27343F;
}

.documentCompliance .tableInformation .tableRightSide .tableTitle .documentMonth.current {
	border: 						1px solid #000000;
	border-bottom: 					unset;
}

.documentCompliance .tableInformation .tableRightSide .tableFooter .monthPercent.current {
	border: 						1px solid #000000;
	border-top: 					unset;
}

.darkMode .documentCompliance .tableInformation .tableRightSide .tableTitle .documentMonth.current {
	border: 						1px solid white;
	border-bottom: 					unset;
}

.darkMode .documentCompliance .tableInformation .tableRightSide .tableFooter .monthPercent.current {
	border: 						1px solid white;
	border-top: 					unset;
}

.documentCompliance .tableInformation .tableRightSide .tableRow {
	display: 						flex;
	flex-flow: 						row nowrap;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer {
	flex: 							0 0 10%;
	max-width: 						10%;
	padding: 						10px 20px;
	transition: 					all 0.3s ease-in-out;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer.current {
	border-left: 					1px solid #000000;
	border-right: 					1px solid #000000;
}

.darkMode .documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer.current {
	border-left: 					1px solid white;
	border-right: 					1px solid white;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer.active,
.documentCompliance .tableInformation .tableRightSide .tableRow:nth-of-type(2n+1) .squareContainer.active {
	background-color: 				var(--darkBlue);
}

.documentCompliance .tableInformation .tableRightSide .tableRow:nth-of-type(2n+1) .squareContainer {
	background-color:				rgba(4, 26, 57, 0.03);
}

.darkMode .documentCompliance .tableInformation .tableRightSide .tableRow:nth-of-type(2n+1) .squareContainer {
	background-color:				#27343F;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare {
	position: 						relative;
	width: 							30px;
	height: 						20px;
	margin: 						auto;
	cursor: 						pointer;
	transition: 					all 0.3s ease-in-out;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.reviewRequestIcon::before {
	content: 						"!";
	color: 							white;
	font-weight: 					700;
	display: 						flex;
	align-items: 					center;
	justify-content: 				center;
	font-size: 						18px;
	width: 							20px;
	height: 						20px;
	border-radius: 					50%;
	background-color: 				var(--red);
	border: 						2px solid white;
	position: 						absolute;
	z-index: 						2;
	top: 							50%;
	left: 							50%;
	transform: 						translate(-50%, -50%);
	
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.noDocumentNeeded {
	background-color: 				#FFFFFF;
	cursor: 						help;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.nonCompliant {
	background-color: 				#FF316F;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.pendingReview {
	background-color: 				rgba(255, 227, 154);
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.compliant {
	background-color: 				#08D1A1;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.futureDocument {
	background-color: 				#E1E1E1;
}

.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer .documentSquare.withComment::after {
	content: 						"";
	position: 						absolute;
	width: 							5px;
	height: 						5px;
	left: 							100%;
	bottom: 						100%;
	border-radius: 					50%;
	background-color: 				rgba(255, 207, 82, 1);
}

.custom-swal-doc {
	display: flex;
	gap: 5px;
	font-size: 12px;
	opacity: 0.5;
}

@media (max-width: 991px) {
	/* TABLE HEADER */
	.documentCompliance .tableHeader {
		flex-flow:  					column nowrap;
		padding-inline: 				10px;
	}

	.documentCompliance .tableHeader .labels {
		width: 							100%;
		justify-content: 				unset;
		overflow-x: 					auto;
		padding-bottom: 				5px;
	}

	.documentCompliance .tableHeader .labels .withDot {
		flex: 							1 0 auto;
	}

	.documentCompliance .tableHeader .clientFilterIcon {
		display: 						none;
	}

	.documentCompliance .tableHeader .clientSelector {
		width: 							fit-content;
	}

	/* TABLE INFORMATION */
	.documentCompliance .tableInformation .tableLeftSide {
		flex: 							0 0 50%;
		max-width: 						50%;
	}

	.documentCompliance .tableInformation .tableLeftSide .tableTitle,
	.documentCompliance .tableInformation .tableLeftSide .tableFooter {
		gap: 							15px;
	}

	.documentCompliance .tableInformation .tableLeftSide .tableFooter .footerTitle {
		font-size: 						12px;
		white-space: 					nowrap;
   		overflow: 						hidden;
    	text-overflow: 					ellipsis;
	}

	.documentCompliance .tableInformation .tableLeftSide .tableRow {
		gap: 							15px;
	}

	.documentCompliance .tableInformation .tableLeftSide .tableRow .documentNumber {
		min-width: 						15px;
	}

	.documentCompliance .tableInformation .tableRightSide {
		flex: 							0 0 50%;
		max-width: 						50%;
	}

	.documentCompliance .tableInformation .tableRightSide .tableTitle .documentMonth,
	.documentCompliance .tableInformation .tableRightSide .tableFooter .monthPercent {
		flex: 							0 0 40%;
		max-width: 						40%;
	}

	.documentCompliance .tableInformation .tableRightSide .tableRow .squareContainer {
		flex: 							0 0 40%;
		max-width: 						40%;
	}
}