.supplierUserTable .insideLabel {
    position:                           absolute;
    top:                                8px;
    z-index:                            2;
    cursor:                             pointer;
    pointer-events:                     none;
    transition:                         all 0.1s ease-out;
}

.supplierUserTable .switch #label-on {
	top: 							    18px;
    left:                               12px;
    color:                              white;
    opacity:                            1;
    visibility:                         visible;
}

.supplierUserTable .switch #label-on.hidden {
    opacity:                            0;
    visibility:                         hidden;
}

.supplierUserTable .switch #label-off {
    left:                               39px;
    color:                              grey;
    opacity:                            1;
    visibility:                         visible;
}

.supplierUserTable .switch #label-off.hidden {
    opacity:                            0;
    visibility:                         hidden;
}

  /* The slider */
.supplierUserTable .slider {
	width: 							    60px;
	height: 						    25px;
    position:                           absolute;
    cursor:                             pointer;
    top:                                18px;
    left:                               18px;
    right:                              0;
    bottom:                             0;
    background-color:                   white;
    -webkit-transition:                 .4s;
    transition:                         .4s;
}

.supplierUserTable .slider:before {
    position:                           absolute;
    content:                            "";
    height:                             20px;
    width:                              20px;
    left:                               3px;
    bottom:                             2.5px;
    background-color:                   white;
    -webkit-transition:                 .35s;
    transition:                         .35s;
}

.supplierUserTable .slider.isChecked:before {
    background-color:                   white;
}

.supplierUserTable input:checked + .slider {
    background-color:                   rgba(8, 209, 161, 1);
}

.supplierUserTable input + .slider {
    background-color:                   grey;
}

.supplierUserTable input:focus + .slider {
    box-shadow:                         0 0 1px rgba(0, 163, 255, 0.1)
}

.supplierUserTable input:checked + .slider:before {
    -webkit-transform:                  translateX(34px);
    -ms-transform:                      translateX(34px);
    transform:                          translateX(34px);
}

  /* Rounded sliders */
.supplierUserTable .slider.round {
    border-radius:                      34px;
}

.supplierUserTable .slider.round:before {
    border-radius:                      50%;
}

.supplierUserTable {
    margin-bottom:                      80px;
}

.supplierUserTable .tableRow {
    display:                            grid;
    grid-template-columns:              4fr repeat(4, 2fr);
}

.supplierUserTable .tableHeader {
    background:                         white;
    box-shadow:                         0px 5px 30px 0px rgba(35, 107, 216, 0.08);
    transition:                         all 0.3s ease-in-out;
}

.darkMode .supplierUserTable .tableHeader {
    background:                         #131E29;
}

.supplierUserTable .tableHeader .headerCol {
    display:                            flex;
    align-items:                        center;
    gap:                                5px;
    padding:                            20px;
}

.supplierUserTable .tableHeader .headerCol > span {
    font-size:                          12px;
    font-weight:                        700;
}

.supplierUserTable .tableHeader .headerCol > img {
    cursor:                             pointer;
}

.supplierUserTable .tableRow:not(:first-child) {
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
}

.darkMode .supplierUserTable .tableRow:not(:first-child) {
	border-bottom: 						1px solid #27343f;
}

.supplierUserTable .tableRow .userInfo {
	position: 							relative;
	padding:                            20px;
}

.supplierUserTable .tableRow .userInfo > span {
	font-size:                          12px;
    font-weight:                        500;
}

.supplierUserTable .tableRow .userInfo .mobileLabel {
	display: 							none;
}

.supplierUserTable .tableRow .userInfo:nth-of-type(2) > span {
	opacity:                            0.5;
}

.supplierUserTable .tableRow .dots{
	position: 							relative;
}

.supplierUserTable .tableRow .userInfo .moreDots {
	justify-self: 						flex-end;
	cursor: 							pointer;
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
}

.darkMode .supplierUserTable .tableRow .userInfo .moreDots{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.supplierUserTable .tableRow .userInfo .moreOptions {
	display: 							flex;
	flex-direction: 					column;
	position: 							absolute;
	width: 								200px;
	top: 								65%;
	right: 								10%;
	background: 						white;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	z-index: 							1;
}

.supplierUserTable .tableRow .userInfo .moreOptions.show {
	opacity: 							1;
	visibility: 						visible;
}

.supplierUserTable .tableRow .userInfo .moreOptions > span {
	padding: 							10px 20px;
	color: 								black;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.supplierUserTable .tableRow .userInfo .moreOptions .deleteOption {
	color: 								var(--red);
}

.supplierUserTable .tableRow .userInfo .moreOptions > span:first-of-type:hover {
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplierUserTable .tableRow .userInfo .moreOptions .deleteOption:hover {
	color: 								white;
	background: 						var(--red);
}

@media (width <= 991px) {
    .supplierUserTable .tableHeader {
		display: 							none;
	}

	.supplierUserTable .tableRow {
		grid-template-columns:              3fr 1fr;
	}
	
	.supplierUserTable .tableRow .userInfo {
		display: 							flex;
		flex-direction: 					column;
	}

	.supplierUserTable .tableRow #usernameCol {
		white-space: 						nowrap;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
	}

	.supplierUserTable .tableRow #usernameCol span {
		white-space: 						nowrap;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
	}
	
	.supplierUserTable .tableRow .userInfo:nth-of-type(2) > span:nth-of-type(1) {
		opacity: 							1;
	}

	.supplierUserTable .tableRow .userInfo .mobileLabel {
		display: 							block;
		width: 								700;
		font-size: 							10px;
		opacity: 							0.4;
		white-space: 						nowrap;
	}

	.supplierUserTable .tableRow #statusCol {
	/* .supplierUserTable .tableRow #activePurchasingCol { */
		width: 								100%;
		align-items: 						flex-end;
		align-items: 						flex-end;
		grid-row: 							2 / 3;
		grid-column: 						2 / -1;
	}

	.supplierUserTable .tableRow #statusCol .slider {
	/* .supplierUserTable .tableRow #activePurchasingCol .slider { */
		left: 								unset;
		right: 								18px;
	}

	.supplierUserTable .tableRow #statusCol {
		grid-row: 							2 / 3;
	}
	
	/* .supplierUserTable .tableRow #activePurchasingCol {
		grid-row: 							3 / 4;
	} */

	.supplierUserTable .tableRow .dots {
		align-items: 						flex-end;
		grid-row: 							1 / 2;
		grid-column: 						2 / -1;
	}

	.supplierUserTable .tableRow .userInfo .moreOptions {
		right: 								20%;
	}
}