/* lable inside the slider */
.insideLabel {
    position:                       absolute;
    top:                            8px;
    z-index:                        2;
    cursor:                         pointer;
    pointer-events:                 none;
    transition:                     all 0.1s ease-out;
}

.userConfig .switch #label-on {
	top: 							18px;
    left:                           12px;
    color:                          white;
    opacity:                        1;
    visibility:                     visible;
}

.userConfig .switch #label-on.hidden {
    opacity:                        0;
    visibility:                     hidden;
}

.userConfig .switch #label-off {
    left:                           39px;
    color:                          grey;
    opacity:                        1;
    visibility:                     visible;
}

.userConfig .switch #label-off.hidden {
    opacity:                        0;
    visibility:                     hidden;
}

  /* The slider */
.userConfig .slider {
	width: 							60px;
	height: 						25px;
    position:                       absolute;
    cursor:                         pointer;
    top:                            18px;
    left:                           18px;
    right:                          0;
    bottom:                         0;
    background-color:               white;
    -webkit-transition:             .4s;
    transition:                     .4s;
}

.userConfig .slider:before {
    position:                       absolute;
    content:                        "";
    height:                         20px;
    width:                          20px;
    left:                           3px;
    bottom:                         2.5px;
    background-color:               white;
    -webkit-transition:             .35s;
    transition:                     .35s;
}

.userConfig .slider.isChecked:before {
    background-color:               white;
}

.userConfig input:checked + .slider {
    background-color:               rgba(8, 209, 161, 1);
}

.userConfig input + .slider {
    background-color:               grey;
}

.userConfig input:focus + .slider {
    box-shadow:                     0 0 1px rgba(0, 163, 255, 0.1)
}

.userConfig input:checked + .slider:before {
    -webkit-transform:              translateX(34px);
    -ms-transform:                  translateX(34px);
    transform:                      translateX(34px);
}

  /* Rounded sliders */
.userConfig .slider.round {
    border-radius:                  34px;
}

.userConfig .slider.round:before {
    border-radius:                  50%;
}

.userConfig .titleAndSearch{
	display: 							grid;
    grid-template-columns:              1fr 1fr;
	align-items: 						center;
	margin-bottom: 						55px;
}

.userConfig .titleAndSearch .searchContainer{
    justify-self:                       flex-end;
    width:                              290px;
}

.userConfig .filters{
	display: 							flex;
	justify-content: 					space-between;
	margin-bottom: 						10px;
}

.userConfig .filters .filtersContainer {
	width: 100%;
	display: 							flex;
	gap: 								10px;
	flex-flow: 							row wrap;
}

.userConfig .filters .filter {
	display: 							flex;
    gap:                                5px;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.userConfig .filters .addUser {
    gap:                                5px;
    padding:                            10px 20px;
	white-space: 						nowrap;
}

.userConfig .filters .filter > img {
    width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	pointer-events: 					none;
}

.userConfig .filters .filter > span {
    font-size: 							12px;
	pointer-events: 					none;
	white-space: 						nowrap;
}

.userConfig .filters .filter::after {
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.userConfig .filters .filter.show::after{
	transform: 							rotate(270deg);
}

.userConfig .filters .filter.darkMode{
	background-color: 					#181E24;
}

.userConfig .filters .filter .filterOptions {
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.userConfig .filters .filter.darkMode .filterOptions {
	background: 						#131E29;
}

.userConfig .filters .filter .filterOptions .optionsScroll {
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.userConfig .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}

 /* Track */
.userConfig .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}

/* Handle */
.userConfig .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

/* Handle */
.userConfig .filters .filter.darkMode .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}

.userConfig .filters .filter .filterOptions.show {
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.userConfig .filters .filter .filterOptions .title {
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.userConfig .filters .filter.darkMode .filterOptions .title {
    -webkit-text-fill-color: 			white;
}

.userConfig .filters .filter .filterOptions .option {
	display: 							flex;
	align-items: 						center;
	padding: 							0 10px;
}


.userConfig .filters .filter .filterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.userConfig .filters .filter.darkMode .filterOptions .option > input{
    background-color:                         white;
}

.userConfig .filters .filter.darkMode .filterOptions .option > input:checked{
    background-color: 					var(--green);
}

.userConfig .filters .filter .filterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding: 							10px 0 10px 10px;
}

.userConfig .filters .filter.darkMode .filterOptions .option > label{
    -webkit-text-fill-color:            white;
}


.userConfig .filters .filter .filterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.userConfig .filters .filter .filterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.userConfig .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}

.userConfig .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.userConfig .filters .filter .filterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.userConfig .filters .filter .filterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.userConfig .filters .filter.darkMode .filterOptions .actions > button {
    -webkit-text-fill-color:            white;
}

.userConfig .filters .filter .filterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

/* Table CSS */
.userConfig .userTable .tableRow {
    display:                            grid;
    /* grid-template-columns:              4fr 2fr 2fr 2fr 3fr 2fr; */
    grid-template-columns:              4fr 2fr 2fr 3fr 2fr;
}

.userConfig .userTable .tableHeader {
    background:                         white;
    box-shadow:                         0px 5px 30px 0px rgba(35, 107, 216, 0.08);
    transition:                         all 0.3s ease-in-out;
}

.userConfig.darkMode .userTable .tableHeader {
    background:                         #131E29;
}

.userConfig .userTable .tableHeader .headerCol {
    display:                            flex;
    align-items:                        center;
    gap:                                5px;
    padding:                            20px;
}

.userConfig .userTable .tableHeader .headerCol > span {
    font-size:                          12px;
    font-weight:                        700;
}

.userConfig .userTable .tableHeader .headerCol > img {
    cursor:                             pointer;
}

.userConfig .userTable .tableRow:not(:first-child) {
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
}

.darkMode .userConfig .userTable .tableRow:not(:first-child) {
	border-bottom: 						1px solid #27343f;
}

.userConfig .userTable .tableRow .userInfo {
	position: 							relative;
	padding:                            20px;
}

.userConfig .userTable .tableRow .userInfo > span {
	font-size:                          12px;
    font-weight:                        500;
}

.userConfig .userTable .tableRow .userInfo .mobileLabel {
	display: 							none;
}

.userConfig .userTable .tableRow .userInfo:nth-of-type(2) > span {
	opacity:                            0.5;
}

.userConfig .userTable .tableRow .dots{
	position: 							relative;
}

.userConfig .userTable .tableRow .userInfo .moreDots {
	justify-self: 						flex-end;
	cursor: 							pointer;
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
}

.darkMode .userConfig .userTable .tableRow .userInfo .moreDots{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.userConfig .userTable .tableRow .userInfo .moreOptions {
	display: 							flex;
	flex-direction: 					column;
	position: 							absolute;
	width: 								200px;
	top: 								65%;
	right: 								10%;
	background: 						white;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	z-index: 							1;
}

.userConfig .userTable .tableRow .userInfo .moreOptions.show {
	opacity: 							1;
	visibility: 						visible;
}

.userConfig .userTable .tableRow .userInfo .moreOptions > span {
	padding: 							10px 20px;
	color: 								black;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.userConfig .userTable .tableRow .userInfo .moreOptions > span:last-of-type {
	color: 								var(--red);
}

.userConfig .userTable .tableRow .userInfo .moreOptions > span:first-of-type:hover {
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.userConfig .userTable .tableRow .userInfo .moreOptions > span:last-of-type:hover {
	color: 								white;
	background: 						var(--red);
}

.userConfig .addAndPagination {
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-bottom: 						10px;
	margin-top: 						80px;
}

.userConfig .addAndPagination nav {
	padding: 							0;
}

.darkMode .userConfig .addAndPagination nav ul li button{
	color: 								white;
	opacity: 							1;
}

.userConfig .tableCount {
	display: 							flex;
	justify-content: 					flex-end;
	margin-bottom: 						30px;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin-right: 						20px;
}

/* ADD USER MODAL CSS */
.addUserModal .modal-content {
	padding: 							40px 40px 30px 60px;
}

.addUserModal .modal-title h4 {
	font-weight: 						700;
}

.addUserModal .modal-title span {
	font-size: 							14px;
	font-weight: 						300;
}

.addUserModal .closeIcon {
	position: 							absolute;
	top: 								15px;
	right: 								15px;
	height: 							25px;
	width: 								25px;
	object-fit: 						cover;
	cursor: 							pointer;
}

.addUserModal.darkMode .closeIcon {
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.addUserModal .modal-body {
	display: 							grid;
	grid-template-columns: 				repeat(3, 1fr);
	column-gap: 						10px;
	row-gap: 							15px;
	padding: 							40px 0;
}

.addUserModal .modal-body #username {
	grid-column: 						1 / -1;
}

.addUserModal .modal-body .actionsContainer {
	display: 							flex;
	gap: 								10px;
	margin-top: 						20px;
	justify-content: 					flex-end;
	grid-column: 						1 / -1;
}

.addUserModal .modal-body .actionsContainer > button:disabled {
	background: 						unset;
	color: 								rgba(195, 195, 195, 1);
	-webkit-text-fill-color: 			rgba(195, 195, 195, 1);
	background-color: 					rgba(235, 235, 235, 1);
}

.addUserModal.darkMode .modal-body .actionsContainer > button:disabled {
	color: 								rgba(135, 135, 135, 1);
	-webkit-text-fill-color: 			rgba(135, 135, 135, 1);
	background-color: 					#27343F;
}

@media (width <= 991px) {
	.userConfig .titleAndSearch {
		grid-template-columns:              3fr 2fr;
		row-gap: 							10px;
		margin-bottom: 						20px;
	}

	.userConfig .titleAndSearch h3 {
		position: 							relative;
		justify-self: 						center;
		grid-column: 						1 / -1;
	}

	.userConfig .titleAndSearch .searchContainer {
		justify-self: 						flex-start;
		width: 								100%;
	}

	.userConfig .titleAndSearch .sortMobile{
		justify-self: 						flex-end;
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		color: 								rgba(0, 0, 0, 0.40);
		font-weight: 						300;
		display: 							flex;
		gap: 								10px;
		align-items: 						center;
		width: 								100px;
		justify-content: 					space-between;
	}

	.userConfig.darkMode .titleAndSearch .sortMobile{
		background-color: 					#131E29;
		color: 								white;
	}

	.userConfig .titleAndSearch .sortMobile::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
	}
	
	.userConfig .titleAndSearch .sortDataContainer{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;
		background: 						var(--grey);
		box-shadow: 						0px -30px 100px 0px rgba(4, 26, 57, 0.55);
		z-index: 							999;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}
	
	.userConfig.darkMode .titleAndSearch .sortDataContainer{
		background: 						#131E29;
	}

	.userConfig .titleAndSearch .sortDataContainer > img {
		position: 							absolute;
		top: 								25px;
		right: 								25px;
		height: 							25px;
		width: 								25px;
		object-fit: 						contain;
	}

	.userConfig.darkMode .titleAndSearch .sortDataContainer > img {
		filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
	}

	.userConfig .titleAndSearch .sortDataContainer.show{
		opacity: 							1;
		visibility: 						visible;
	}

	.userConfig .titleAndSearch .sortDataContainer > .title{
		font-size: 							16px;
		font-weight: 						700;
		margin-bottom: 						30px;
	}

	.userConfig .titleAndSearch .sortDataContainer > span{
		font-size: 							12px;
		margin-bottom: 						20px;
	}

	.userConfig .titleAndSearch .sortDataContainer > span:last-of-type{
		margin-bottom: 						0;
	}

	.userConfig .filters {
		overflow-x: 						hidden;
		flex-direction: 					column;
		gap: 								10px;
	}
	
	.userConfig .filters .filtersContainer {
		flex-wrap: 							nowrap;
		overflow-x: 						auto;
		overflow-y: 						hidden;
		order: 								1;
	}

	.userConfig .filters .filtersContainer::-webkit-scrollbar {
		display: 							none;
	}

	.userConfig .filters .filter .filterOptions {
		position: 							fixed;
		width: 								90vw;
		height: 							max-content;
		top: 								50%;
		left: 								50%;
		transform: 							translate(-50%, -50%);
		border-radius: 						35px;
		padding: 							50px 35px;
	}

	.userConfig .filters .filter .filterOptions > img {
		position: 							absolute;
		top: 								20px;
		right: 								20px;
		width: 								25px;
		height: 							25px;
		object-fit: 						contain;
	}
	.userConfig.darkMode .filters .filter .filterOptions > img {
		filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
	}

	.userConfig .filters .filter .filterOptions .title {
		display: 							flex;
		gap: 								10px;
		margin-bottom: 						30px;
	}

	.userConfig .filters .filter .filterOptions .option {
		padding: 							0;
	}

	.userConfig .filters .filter .filterOptions .actions {
		margin-top: 						40px;
		justify-content: 					space-between;
	}

	.userConfig .filters .addUser {
		align-self: 						flex-end;
		order: 								0;
	}
	
	.userConfig .userTable .tableHeader {
		display: 							none;
	}

	.userConfig .userTable .tableRow {
		grid-template-columns:              3fr 1fr;
	}
	
	.userConfig .userTable .tableRow .userInfo {
		display: 							flex;
		flex-direction: 					column;
	}

	.userConfig .userTable .tableRow #usernameCol {
		white-space: 						nowrap;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
	}

	.userConfig .userTable .tableRow #usernameCol span {
		white-space: 						nowrap;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
	}
	
	.userConfig .userTable .tableRow .userInfo:nth-of-type(2) > span:nth-of-type(1) {
		opacity: 							1;
	}

	.userConfig .userTable .tableRow .userInfo .mobileLabel {
		display: 							block;
		width: 								700;
		font-size: 							10px;
		opacity: 							0.4;
		white-space: 						nowrap;
	}

	.userConfig .userTable .tableRow #statusCol,
	.userConfig .userTable .tableRow #activePurchasingCol {
		width: 								100%;
		align-items: 						flex-end;
		align-items: 						flex-end;
		grid-row: 							2 / 3;
		grid-column: 						2 / -1;
	}

	.userConfig .userTable .tableRow #statusCol .slider,
	.userConfig .userTable .tableRow #activePurchasingCol .slider {
		left: 								unset;
		right: 								18px;
	}

	.userConfig .userTable .tableRow #statusCol {
		grid-row: 							2 / 3;
	}
	
	.userConfig .userTable .tableRow #activePurchasingCol {
		grid-row: 							3 / 4;
	}

	.userConfig .userTable .tableRow .dots {
		align-items: 						flex-end;
		grid-row: 							1 / 2;
		grid-column: 						2 / -1;
	}

	.userConfig .userTable .tableRow .userInfo .moreOptions {
		right: 								20%;
	}

	.addUserModal .modal-content {
		border-radius: 						20px;
	}

	.addUserModal .modal-body {
		grid-template-columns: 				1fr;
		padding: 							20px 0 40px 0;
	}

	.addUserModal .modal-body .actionsContainer {
		flex-direction: 					column;
	}

	.addUserModal .modal-body .actionsContainer .btnBgBlueTextGradient {
		order: 								0;
	}

	.addUserModal .modal-body .actionsContainer .btnBgWhiteTextBlack {
		order: 								1;
	}
}
