/* 	USER LOGGED */
.homeClass {
    transition:                         all 0.3s ease-in-out;
}

.userLogged{
	width: 								fit-content;
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
	margin: 							85px 0 75px auto;
	position: 							relative;
	cursor: 							pointer;
	padding-right: 						30px;
}

.userLogged::after{
	content: 							"";
	width: 								25px;
	height: 							25px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size:					15px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								0;
	margin:  							auto 0;
	transform: 							rotate(90deg);
	transition: 						all 0.3s ease-in-out;
}

.userLogged.active::after{
	transform: 							rotate(270deg);
}

.userLogged .userImage{
	width: 								35px;
	height: 							35px;
	object-fit: 						cover;
	object-position: 					center center;
	border-radius: 						100px;
	border: 							3px solid transparent;
	background: 						var(--gradient) center center no-repeat;
	background-size: 					35px 35px;
	background-clip: 					border-box;
}

.userLogged .userOptions {
	position: 							absolute;
	top: 								120%;
	right: 								0;
	display: 							flex;
	flex-direction: 					column;
    background-color:                   white;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	width: 								300px;
	z-index: 							10;
}

.darkMode .userLogged .userOptions {
    background-color:               unset;
}

.userLogged .userOptions.show {
	opacity: 							1;
	visibility: 						visible;
}

.userLogged .userOptions .header {
	font-weight: 						600;
	padding: 							10px 20px;
	cursor: 							default;
}

.darkMode .userLogged .userOptions .header {
	background: 						#131E29;
}

.userLogged .userOptions .profilesContainer {
    max-height:                         300px;
    overflow:                           auto;
}

/* width */
.userLogged .userOptions .profilesContainer::-webkit-scrollbar {
	width: 								3px;
	height: 							3px;
}
  
 /* Track */
.userLogged .userOptions .profilesContainer::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.userLogged .userOptions .profilesContainer::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.userLogged .userOptions .profileContainer {
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
	padding: 							10px 15px;
	width: 								100%;
}

.darkMode .userLogged .userOptions .profileContainer {
	background: 						#131E29;
}

.userLogged .userOptions .profileContainer::after {
	content: 							"";
    margin-left: 						auto;
	width: 								15px;
	height: 							15px;
	mask: 								url("../../images/arrowForward.svg") center center no-repeat;
	mask-size: 							15px;
	background: 						var(--gradient);
}

.userLogged .userOptions .profileContainer:hover {
	background: 						linear-gradient(180deg, rgba(0, 154, 255, 20%), rgba(140, 165, 255, 20%));
}

.darkMode .userLogged .userOptions .profileContainer:hover {
	background: 						#131E29;
}

.userLogged .userOptions .profileContainer #profileLogo{
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	width: 								35px;
	height: 							35px;
	border-radius: 						50%;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, border-box;
	-webkit-background-clip: 			text, border-box;
	color: 								transparent;
}

.userLogged .userOptions .profileContainer .profileInfo {
	display: 							flex;
	flex-direction: 					column;
}

.userLogged .userOptions .profileContainer .profileInfo #profileName {
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.darkMode .userLogged .userOptions .profileContainer .profileInfo #profileName {
	background: 						white;
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.userLogged .userOptions .profileContainer:hover .profileInfo #profileName {
    background: 						var(--gradient);
    background-clip: 					text;
    -webkit-background-clip: 			text;
    -webkit-text-fill-color: 			transparent;
}

.darkMode .userLogged .userOptions .profileContainer:hover {
    background:                         linear-gradient(180deg, #0F3754, #2A3954);
}

.darkMode .userLogged .userOptions .profileContainer:hover .profileInfo #profileName {
    background: 						white;
    background-clip: 					text;
    -webkit-background-clip: 			text;
    -webkit-text-fill-color: 			transparent;
}

.userLogged .userOptions .profileContainer .profileInfo #profileRol {
	font-size: 							10px;
	color: 								rgba(63, 109, 180, 1);
}

.userLogged .logOut{
	text-decoration: 					none;
	display: 							flex;
	align-items: 						center;
	width: 								100%;
	padding: 							15px 55px;
	border: 							none;
	background: 						var(--gradient), #131E29;
	background-clip: 					text, border-box;
	-webkit-background-clip: 			text, border-box;
	-webkit-text-fill-color: 			transparent;
    z-index:                            2;
}


.darkMode .userLogged .logOut{
	background: 						var(--gradient), linear-gradient(180deg, #0F3754, #2A3954);
	background-clip: 					text, border-box;
	-webkit-background-clip: 			text, border-box;
	-webkit-text-fill-color: 			transparent;
}

.userLogged .logOut::after{
	content: 							"";
	position:                           absolute;
	right: 								15px;
	width: 								25px;
	height: 							25px;
	background: 						url("../../images/logout.svg") center center no-repeat;
	background-size: 					25px;
}

@media(max-width: 991px){
	.homeClass.mobileMenuOpen {
		overflow: 						hidden;
		height: 						100vh;
	}
	
	.logoAndMenuMobile{
		display: 						flex;
		align-items: 					center;
		justify-content: 				space-between;
		padding: 						25px 13px;
	}

	.logoAndMenuMobile .logo{
		height: 						35px;
		object-fit: 					contain;
		object-position: 				center center;
	}
}