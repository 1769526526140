/* NAVIGATION MENU */
.navigationMenu{
	display: 							flex;
	flex-flow: 							column nowrap;
	width: 								60px;
	height: 							100vh;
	background: 						#FFFFFF;
	box-shadow: 						15px 15px 100px 0px rgba(4, 26, 57, 0.10);
	padding: 							50px 0;
	position: 							fixed;
	top: 								0;
	left: 								0;
	bottom: 							0;
	transition: 						all 0.3s ease-in-out;
	overflow: 							hidden;
}

.navigationMenu .darkModeIcon {
    margin-top:                         auto;
}

.navigationMenu .iconProdensa{
	width: 								25px;
	height: 							45px;
	object-fit: 						contain;
	object-position: 					center center;
	margin:  							0 auto 50px;
}

.navigationMenu .logo{
	display: 							none;
	height: 							45px;
	object-fit: 						contain;
	object-position: 					center center;
	margin:  							0 auto 50px;
}

.navigationMenu .optNavContainer {
	display: 							flex;
	flex-flow: 							column nowrap;
	max-height: 						90%;
	overflow-y: 						auto;
	overflow-x: 						hidden;
}

.navigationMenu .optNavContainer::-webkit-scrollbar {
	width: 								2px;
	height: 							2px;
}

.navigationMenu .optNavContainer::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}

.navigationMenu .optNavContainer::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.navigationMenu .btnNav{
	min-width: 							max-content;
	border:  							unset;
	background-color: 					transparent;
	padding: 							20px;
	display: 							flex;
	align-items: 						center;
	transition: 						all 0.3s ease-in-out;
}

.navigationMenu.darkMode .btnNav{
	color: 								white;
}

.navigationMenu .btnNav.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.navigationMenu .btnNav.darkMode.active{
	background: 						var(--gradient), #243D55;
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.navigationMenu .btnNav .iconBtnNav{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						25px;
}

.navigationMenu .btnNav .arrowBtnNav{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-left: 						auto;
}

.navigationMenu .iconGrowMenu{
	width: 								fit-content;
	height: 							10px;
	padding: 							20px;
	background: 						url("../../images/iconGrowMenu.svg") left center no-repeat;
	background-size: 					15px 15px;
	margin: 							0 0 0 auto;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.navigationMenu .iconGrowMenu.darkMode{
    background: 						url("../../images/iconGrownWhite.svg") left center no-repeat;
    background-size: 					15px 15px;
}

/* MENU OPEN CSS */
.navigationMenu.grow{
	width: 								315px;
}

.navigationMenu.grow .iconProdensa{
	display: 							none;
}

.navigationMenu.grow .logo{
	display: 							block;
}

.navigationMenu.grow .btnNav{
	padding: 							20px 40px;
}

.navigationMenu.grow .iconGrowMenu{
	margin: 							0 20px 0 auto;
	transform: 							rotate(-180deg);
}

.navigationMenu .hamburguer{
	display: 							none;		
}

.navigationMenu .mobileActionsContainer{
	display: 							none;
}

.navigationMenu .profilesContainer {
	display: 							none;
}

@media (max-width: 991px){
	/* NAVIGATION MENU */
	.navigationMenu{
		display: 							flex;
		flex-direction: 					column;
		gap: 								40px;
		width: 								100vw;
		height: 							100%;
		background: 						rgba(255, 255, 255, 0.95);
		box-shadow: 						0px 30px 100px 0px rgba(4, 26, 57, 0.55);
		padding: 							25px;
		position: 							fixed;
		top: 								0;
		left: 								-100%;
		bottom: 							0;
		overflow: 							hidden;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
		z-index: 							1000;
	}

	.navigationMenu .iconProdensa{
		display: 							none;
	}

	.navigationMenu.show{
		left: 								0;
		opacity: 							1;
		visibility: 						visible;
	}

	.navigationMenu .header {
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
	}
	
	.navigationMenu .optNavContainer {
		max-height: 						80%;
		opacity: 							1;
		visibility: 						visible;
		transition: 						all 0.3s ease-in-out;
	}

	.navigationMenu .optNavContainer.swapProfileOpen {
		opacity: 							0;
		visibility: 						hidden;
	}

	.navigationMenu .profilesContainer {
		position: 							absolute;
		top: 								15%;
		left: 								50%;
		transform: 							translateX(-50%);
		display: 							flex;
		flex-direction: 					column;
		height: 100vh;
		gap: 								15px;
		width: 								60%;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}
	
	.navigationMenu .profilesContainer.swapProfileOpen {
		opacity: 							1;
		visibility: 						visible;
	}

	.navigationMenu .profilesContainer .profilesHeader {
		display: 							flex;
		align-items: 						center;
		gap: 								10px;
		font-weight: 						700;
	}

	.navigationMenu .profilesContainer .profilesHeader img {
		transform: 							rotate(180deg);
		width: 								15px;
		height: 							15px;
	}

	.navigationMenu .profilesContainer .profiles {
		display: 							flex;
		flex-direction: 					column;
		gap: 								5px;
		max-height: 						50%;
		overflow: 							auto;
	}

		/* width */
	.navigationMenu .profilesContainer .profiles::-webkit-scrollbar {
		width: 								3px;
		height: 							3px;
	}
	
	/* Track */
	.navigationMenu .profilesContainer .profiles::-webkit-scrollbar-track {
		background: 						rgba(0, 0, 0, 0.05);
	}
	
	/* Handle */
	.navigationMenu .profilesContainer .profiles::-webkit-scrollbar-thumb {
		background: 						var(--darkBlue);
	}

	.navigationMenu .profilesContainer .profiles .profileContainer {
		display: 							flex;
		gap: 								10px;
		align-items: 						center;
		padding: 							10px 15px;
		width: 								100%;
	}
	
	.darkMode .navigationMenu .profilesContainer .profiles .profileContainer {
		background: 						#131E29;
	}
	
	.navigationMenu .profilesContainer .profiles .profileContainer::after {
		content: 							"";
		margin-left: 						auto;
		width: 								15px;
		height: 							15px;
		mask: 								url("../../images/arrowForward.svg") center center no-repeat;
		mask-size: 							15px;
		background: 						var(--gradient);
	}
	
	.navigationMenu .profilesContainer .profiles .profileContainer #profileLogo{
		display: 							flex;
		align-items: 						center;
		justify-content: 					center;
		width: 								35px;
		height: 							35px;
		border-radius: 						50%;
		background: 						var(--gradient), var(--blue);
		background-clip: 					text, border-box;
		-webkit-background-clip: 			text, border-box;
		color: 								transparent;
	}
	
	.navigationMenu .profilesContainer .profiles .profileContainer .profileInfo {
		display: 							flex;
		flex-direction: 					column;
	}
	
	.navigationMenu .profilesContainer .profiles .profileContainer .profileInfo #profileRol {
		font-size: 							10px;
		color: 								rgba(63, 109, 180, 1);
	}

	.navigationMenu .hamburguer{
		display: 							block;
		width: 								18px;
		height: 							12px;
		object-fit: 						contain;
		object-position: 					center center;
	}

	.navigationMenu .logo{
		display: 							inline-block;
		height: 							35px;
		margin:  							0;
	}

	.navigationMenu .btnNav{
		width: 								fit-content;
		font-size: 							16px;
		border:  							unset;
		background-color: 					transparent;
		padding: 							0;
		margin: 							0 auto;
		margin-bottom: 						35px;
		gap: 								15px;
	}

	.navigationMenu .btnNav.active{
		background: 						transparent;
		background-clip: 					unset;
		-webkit-background-clip: 			unset;
		-webkit-text-fill-color: 			#000000;
	}

	.navigationMenu .btnNav:nth-last-of-type(1){
		margin-bottom: 						0;
	}

	.navigationMenu .btnNav .iconBtnNav{
		margin-right: 						0;
	}

	.navigationMenu .btnNav .arrowBtnNav{
		display: 							none;
	}

	.navigationMenu .mobileActionsContainer {
		display: 							flex;
		justify-content: 					space-around;
		opacity: 							1;
		visibility: 						visible;
		transition: 						all 0.3s ease-in-out;
	}

	.navigationMenu .mobileActionsContainer.swapProfileOpen {
		opacity: 							0;
		visibility: 						hidden;
	}

	.navigationMenu .btnBgBlueTextGradient{
		width: 								fit-content;
		display: 							flex;
		font-size: 							16px;
		padding: 							10px 50px;
	}

	.navigationMenu .btnBgBlueTextGradient::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/logout.svg") center center no-repeat;
		background-size: 					20px;
		margin-left: 						10px;
	}

	.navigationMenu .swapProfile::after{
		background: 						url("../../images/autorenew.svg") center center no-repeat;
	}

	.navigationMenu .iconGrowMenu{
		display: 							none;		
	}
}