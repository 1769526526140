/* COMPLIANCE SETUP */
.complianceSetup .header{
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	background-color: 					#FFFFFF;
	padding: 							50px 100px;
	box-shadow: 						0 4px 30px 0 rgba(35, 107, 216, 0.08);
	transition: 						background 0.3s ease-in-out;
}

.darkMode .complianceSetup .header{
	background: 						#27343F;
}

.complianceSetup .header .profilePhotoContainer{
	width: 								90px;
    height: 							90px;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) padding-box, var(--gradient) border-box;
	margin-right: 						15px;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	position: 							relative;
	overflow: 							hidden;
}

.complianceSetup .header .profilePhotoContainer .inputProfilePhoto{
	position: 							absolute;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	opacity: 							0;
	cursor: 							pointer;
}

.complianceSetup .header .profilePhotoContainer .profilePhoto{
	width: 								80px;
	height: 							80px;
	object-fit: 						contain;
	object-position: 					center center;
	pointer-events: 					none;
}

.complianceSetup .header .titleLocation{
	margin-right: 						15px;
}

.complianceSetup .header .titleLocation > span{
	font-size: 							12px;
	opacity: 							0.5;
}

.complianceSetup .tabs-container{
	position: 							relative;
}

.complianceSetup .nav-tabs{
	padding-top: 						35px;
	width: 								84%;
}

.complianceSetup .tabs-container .addUserButton {
	display: 							flex;
	gap: 								5px;
	align-items: 						center;
	position: 							absolute;
	right: 								0;
	top: 								32px;
	font-size: 							14px;
	padding: 							10px 30px;
}

.complianceSetup .saveChanges{
	border: 							unset;
	border-radius: 						100px;
	background-color: 					var(--blue);
	padding: 							10px;
	transition: 						all 0.3s ease-in-out;
	position: 							fixed;
	right: 								30px;
	bottom: 							30px;
	z-index: 							999;
}

.complianceSetup .saveChanges:hover{
	background-color: 					var(--darkBlue);
}


.complianceSetup .saveChanges{
	border: 							unset;
	border-radius: 						100px;
	background-color: 					var(--blue);
	padding: 							10px;
	transition: 						all 0.3s ease-in-out;
	position: 							fixed;
	right: 								30px;
	bottom: 							30px;
	z-index: 							999;
}

.complianceSetup .saveChanges:hover{
	background-color: 					var(--darkBlue);
}

.complianceSetup .actionButtons{
	display: 							flex;
	gap: 								15px;
	align-items: 						center;
	justify-content: 					flex-end;	
	margin-bottom: 						100px;
}

.complianceSetup .actionButtons > p{
	width: 								41%;
	font-size: 							12px;
	font-weight: 						300;
	margin-right: 						auto;
}

.complianceSetup .actionButtons > p > a{
	display: 							inline-block;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.complianceSetup .actionButtons .btnBgBlueTextGradient:first-of-type{
	color: 								#000000;
	background-color: 					transparent;
	-webkit-text-fill-color: 			unset;
}


.darkMode .complianceSetup .actionButtons .btnBgBlueTextGradient:first-of-type{
	color: 								white;
	background-color: 					#27343F;
	-webkit-text-fill-color: 			unset;
}

@media (max-width: 991px){
	/* COMPLIANCE SETUP */
	.complianceSetup .header{
		flex-flow: 							column nowrap;
		padding: 							70px 35px 35px;
	}

	.complianceSetup .header .profilePhotoContainer {
		margin-bottom: 						10px;
	}

	.complianceSetup .header .titleLocation{
		margin-right: 						0;
	}

	.complianceSetup .header .titleLocation > h3{
		text-align: 						center;
		font-size: 							23px;
		margin-bottom: 						5px;
	}

	.complianceSetup .header .titleLocation > span{
		text-align: 						center;
	}

	.complianceSetup .tabs-container {
		margin-top: 						60px;
	}
	
	.complianceSetup .tabs-container .addUserButton {
		top: 								unset;
		bottom: 							99.8%;
	}
	
	.complianceSetup .nav-tabs{
		padding-top: 						20px;
		width: 								100%;
	}

	.complianceSetup .actionButtons{
		display: 							flex;
		flex-flow: 							row wrap;
		gap: 								15px;
		align-items: 						center;
		justify-content: 					center;	
		margin-bottom: 						100px;
	}

	.complianceSetup .actionButtons > p{
		width: 								100%;
		font-size: 							10px;
		text-align: 						center;
		margin-right: 						0;
	}
}