/* SUPPLIER DIRECTORY */
.supplier .titleAndSearch{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.supplier .titleAndSearch .searchAndSort{
	flex: 								0 0 25%;
}

.supplier .titleAndSearch .searchAndSort .searchContainer input{
	padding-right: 						50px;
}

.supplier .filters{
	display: 							flex;
    align-items:                        center;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin-bottom: 						10px;
}

.supplier .filters .filter,
.supplier .filters .checkboxFilter {
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.supplier .filters .checkboxFilter {
	gap: 								10px;
}

.supplier .filters .filter.darkMode,
.supplier .filters .checkboxFilter.darkMode {
	background-color: 					#181E24;
}

.supplier .filters .filter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplier .filters .filter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplier .filters .filter.darkMode.active{
    background: 						var(--gradient), #243D55;
    background-clip: 					text, padding-box;
    -webkit-background-clip: 			text, padding-box;
    -webkit-text-fill-color: 			transparent;
}

.supplier .filters .filter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.supplier .filters .filter.show::after{
	transform: 							rotate(270deg);
}

.supplier .filters .filter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.supplier .filters .filter > span {
	font-size: 							12px;
	pointer-events: 					none;
}

.supplier .filters .checkboxFilter > label {
	font-size: 							12px;
	cursor: 							pointer;
}

.darkMode .supplier .filters .checkboxFilter > input[type="checkbox"]  {
	background-color: 					#181E24;
	border: 							1px solid #009AFF;
}

.supplier .filters .filter .searchContainer{
	width: 								280px;
	background-color: 					#FFFFFF;
	padding: 							15px;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.darkMode .supplier .filters .filter .searchContainer{
	background-color: 					#131E29;
}

.supplier .filters .filter .searchContainer::after{
	right: 								35px;
}

.supplier .filters .filter .searchContainer > input{
	background-color: 					var(--grey);
}

.darkMode .supplier .filters .filter .searchContainer > input{
	background-color: 					#27343F;
}

.supplier .filters .filter .filterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.supplier .filters .filter.darkMode .filterOptions{
	background: 						#131E29;
}

.supplier .filters .filter .filterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.supplier .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.supplier .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.supplier .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

/* Handle */
.supplier .filters .filter.darkMode .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}

.supplier .filters .filter .filterOptions.show, .supplier .filters .filter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.supplier .filters .filter .filterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.supplier .filters .filter.darkMode .filterOptions .title{
    -webkit-text-fill-color: 			white;
}

.supplier .filters .filter .filterOptions .option{
	display: 							flex;
	align-items: 						center;
	padding: 							0 10px;
}

.supplier .filters .filter .filterOptions.rating{
	padding: 							20px 0;
}

.supplier .filters .filter .filterOptions.rating .title{
	padding: 							0 20px;
}

.supplier .filters .filter .filterOptions.rating .option{
	padding: 							0;
}

.supplier .filters .filter .filterOptions.location{
	width: 								280px;
	top: 								290%;
}

.supplier .filters .filter .filterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.supplier .filters .filter.darkMode .filterOptions .option > input{
    background-color:                         white;
}

.supplier .filters .filter.darkMode .filterOptions .option > input:checked{
    background-color: 					var(--green);
}

.supplier .filters .filter .filterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding: 							10px 0 10px 10px;
}

.supplier .filters .filter.darkMode .filterOptions .option > label{
    -webkit-text-fill-color:            white;
}

.supplier .filters .filter .filterOptions .option > .ratingFilter{
	width: 								100%;
	display: 							flex;
	align-items: 						center;
	font-size: 							12px;
	gap: 								5px;
	padding: 							5px 30px;
	cursor: 							pointer;
}

.darkMode .supplier .filters .filter .filterOptions .option > .ratingFilter{
	-webkit-text-fill-color: 			white;
}

.supplier .filters .filter .filterOptions .option > .ratingFilter:hover{
	background: 						rgba(4, 26, 57, 0.05);
}

.supplier .filters .filter .filterOptions .option > .ratingFilter::after{
	content: 							"";
	width: 								15px;
	height: 							15px;
	mask: 								url("../../images/starBlack.svg");
	mask-size: 							15px 15px;
}

.darkMode .supplier .filters .filter .filterOptions .option > .ratingFilter::after{
	content: 							"";
	width: 								15px;
	height: 							15px;
	mask: 								url("../../images/starBlack.svg");
	mask-size: 							15px 15px;
	background-color: 					white;
}

.supplier .filters .filter .filterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.supplier .filters .filter .filterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.supplier .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.supplier .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.supplier .filters .filter .filterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.supplier .filters .filter .filterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.supplier .filters .filter.darkMode .filterOptions .actions > button {
    -webkit-text-fill-color:            white;
}

.supplier .filters .filter .filterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplier .filters > .resetAll,
.supplier > .resetAll {
    font-size:                          14px;
    font-size:                          700;
	background: 						var(--gradient) text;
    -webkit-text-fill-color:            transparent;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.supplier .filters > .resetAll.show,
.supplier > .resetAll.show {
	opacity: 							1;
	visibility: 						visible;
}

.supplier .filters > .resetAll:hover {
	opacity:                            0.5;
}

.supplier .tableSuppliers{
	margin-bottom: 						30px;
}

.supplier .tableSuppliers thead th:nth-of-type(1){
	width: 								30%;
}

.supplier .tableSuppliers thead th:nth-of-type(2){
	width: 								20%;
}

.supplier .tableSuppliers thead th:last-of-type{
	width: 								15%;
	position: 							relative;
	padding-right: 						50px;
}

.supplier .tableSuppliers thead th:last-of-type .printReport {
	width: 								20px;
    height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
    cursor: 							pointer;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								25px;
	margin: 							auto 0;
}


.supplier .tableSuppliers tbody td:first-of-type .infoContainer.withBadge {
	padding-left: 						70px;
}

.supplier .tableSuppliers tbody td:first-of-type .infoContainer > span {
	opacity: 							1;
	cursor: 							pointer;
}

.supplier .tableSuppliers tbody td:first-of-type .infoContainer > span:hover {
	background:							var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.supplier .tableSuppliers tbody .infoContainer > .cvpBadge{
	width: 								60px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	left: 								0;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.supplier .tableSuppliers tbody .infoContainer .viewProfile{
	width: 								18px;
	height: 							18px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.darkMode .supplier .tableSuppliers tbody .infoContainer .viewProfile{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.supplier .tableSuppliers tbody td:last-of-type .infoContainer {
	justify-content: 					space-between;
}

.supplier .tableSuppliers tbody .starRating{
	display: 							inline;
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-left: 						5px;
	opacity: 							1;
}

.supplier .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						10px;
}

.supplier .addAndPagination .btnBgBlueTextGradient{
	padding: 							10px 15px;
}

.supplier .addAndPagination .btnBgBlueTextGradient::before{
	content: 							"";
	width: 								18px;
	height: 							18px;
	background: 						url("../../images/add.svg") center center no-repeat;
	background-size: 					18px 18px;
	margin-right: 						5px;
}

.supplier .addAndPagination .pagination{
	display: 							flex;
	align-items: 						center;
	gap: 								5px;
}

.supplier .addAndPagination .number{
	border: 							unset;
	font-size: 							12px;
	padding: 							2px 7.5px;
	opacity: 							0.5;
}

.supplier .addAndPagination .number.active{
	opacity: 							1;
	font-weight: 						900;
	background:  						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplier .addAndPagination .nextPage{
	width: 								13px;
	height: 							13px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.supplier .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

@media (max-width: 991px){
	/* REQUESTS DIRECTORY */
	.supplier .titleAndSearch{
		justify-content: 					unset;
		flex-flow: 							column nowrap;
		margin-bottom: 						20px;
	}

	.supplier .titleAndSearch .title{
		margin-bottom: 						30px;
	}

	.supplier .titleAndSearch .searchAndSort{
		width: 								100%;
		flex: 								0 0 100%;
		display: 							flex;
		gap: 								40px;
	}

	.supplier .titleAndSearch .searchContainer{
		flex: 								1 0 0;
	}

	.supplier .titleAndSearch .searchContainer input{
		padding-right: 						35px;
		height: 							100%;
	}

	.supplier .titleAndSearch .searchAndSort .sortMobile{
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		color: 								rgba(0, 0, 0, 0.40);
		font-weight: 						300;
		display: 							flex;
		gap: 								40px;
		align-items: 						center;
	}

	.supplier.darkMode .titleAndSearch .searchAndSort .sortMobile{
		background-color: 					#131E29;
		color: 								white;
	}

	.supplier .titleAndSearch .searchAndSort .sortMobile::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
	}

	.supplier .titleAndSearch .searchAndSort .sortDataContainer{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;
		background: 						var(--grey);
		box-shadow: 						0px -30px 100px 0px rgba(4, 26, 57, 0.55);
		z-index: 							999;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}

	.supplier.darkMode .titleAndSearch .searchAndSort .sortDataContainer{
		background: 						#131E29;
	}

	.supplier .titleAndSearch .searchAndSort .sortDataContainer::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .supplier .titleAndSearch .searchAndSort .sortDataContainer::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.supplier .titleAndSearch .searchAndSort .sortDataContainer.show{
		opacity: 							1;
		visibility: 						visible;
	}

	.supplier .titleAndSearch .searchAndSort .sortDataContainer > .title{
		font-size: 							16px;
		font-weight: 						700;
	}

	.supplier .titleAndSearch .searchAndSort .sortDataContainer > span{
		font-size: 							12px;
		margin-bottom: 						20px;
	}

	.supplier .titleAndSearch .searchAndSort .sortDataContainer > span:last-of-type{
		margin-bottom: 						0;
	}

	.supplier .filters{
		display: 							flex;
		gap: 								10px;
		overflow-x: 						auto;
		flex-wrap: 							nowrap;
	}

	/* width */
	.supplier .filters::-webkit-scrollbar {
		display: 							none;
	}
	
	.supplier .filters .filter{
		padding: 							10px;
		position: 							static;
	}

	.supplier .filters .filter > span,
	.supplier .filters .checkboxFilter > label {
		font-size: 							10px;
	}

	.supplier .filters .filter .searchContainer{
		width: 								90vw;
		height: 							fit-content;
		padding: 							15px;
		position: 							fixed;
		top: 								unset;
		right: 								0;
		bottom: 							30px;
		margin: 							0 auto;
	}

	.supplier .filters .filter .filterOptions{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;

	}
	.supplier .filters .filter .filterOptions .closeImg {
		width: 								25px;
		height: 							25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .supplier .filters .filter .filterOptions .closeImg {
		filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
	}

	.supplier .filters .filter .filterOptions .imgMenu{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		margin-right: 						10px;
		display: 							inline-block;
	}

	.supplier .filters .filter .filterOptions .title{
		display: 							inline-block;
		font-size: 							16px;
		margin-bottom: 						30px;
	}

	.supplier .filters .filter .filterOptions .option{
		padding: 							0;
	}

	.supplier .filters .filter .filterOptions.location{
		width: 								90vw;
		top: 								0;
	}

	.supplier .filters .filter .filterOptions .option > input{
		order: 								2;
		margin-right: 						10px;
	}

	.supplier .filters .filter .filterOptions .option > label{
		order: 								1;
		padding:  							10px 10px 10px 0;
	}

	.supplier .filters .filter .filterOptions .actions{
		gap: 								0;
		justify-content: 					space-between;
		margin-top: 						50px;
	}

	.supplier .filters .filter .filterOptions .actions > button{
		font-size: 							14px;
	}

    .supplier .filters > .resetAll {
        display:                            none;
    }

    .supplier > .resetAll {
        max-height:                         0;
        text-align:                         center;
    }

    .supplier > .resetAll.show {
        max-height:                         50px;
    }

	.supplier .tableSuppliers{
		display: 							none;
	}

	.supplier .supplierRequests{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.supplier .supplierRequests .supplierRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
	}

	.supplier .supplierRequests .supplierRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.supplier .supplierRequests .supplierRow .supplierHeader{
		display: 							flex;
		align-items: 						center;
		gap: 								10px;
		margin-bottom: 						10px;
	}

	.supplier .supplierRequests .supplierRow .supplierHeader .cvpBadge{
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
	}

	.supplier .supplierRequests .supplierRow .supplierHeader .supplierName{
		font-weight: 						500;
		flex: 								1 0 0;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
		display: 							-webkit-box;
		-webkit-line-clamp: 				2;
		-webkit-box-orient: 				vertical;
	}

	.supplier .supplierRequests .supplierRow .supplierHeader .rating{
		font-size: 							12px;
		font-weight: 						400;
	}

	.supplier .supplierRequests .supplierRow .supplierHeader .rating > .starRating{
		display: 							inline-block;
		margin-top: 						-3px;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation{
		width: 								100%;
		display:  							grid;
		align-items: 						flex-end;
		justify-content: 					space-between;
		grid-template-columns: 				repeat(2, fit-content);
		grid-template-rows: 				repeat(4, max-content);
		column-gap: 						110px;
		row-gap: 							10px;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation .supplierValue{
		font-size: 							12px;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation .supplierTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(1){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(2){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom:						10px;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(3){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(4){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom: 						10px;
	}
	
	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(5){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(6){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					4;
		grid-row-end: 						5;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(7){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.supplier .supplierRequests .supplierRow .supplierInformation > span:nth-of-type(8){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					4;
		grid-row-end: 						5;
	}

	.supplier .addAndPagination{
		padding: 							0 20px;
	}

	.supplier .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}
}