body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 						"DM sans", sans-serif !important;
	background-color: 					var(--grey);
	color: 								#000000 !important;
	transition: 						background-color 0.3s ease-in-out;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root{
	min-height: 						100vh;
}

:root{
	--gradient: 						linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%);
	--darkBlue: 						#041A39;
	--blue: 							rgba(0, 162, 255, 0.10);
	--green:							#08D1A1;
	--darkGreen:						#16B992;
	--red: 								#FF316F;
	--darkRed: 							#D3004C;
	--grey: 							#F5F6F7;
	--orange: 							#FF7B31;
	--yellow: 							#FFCF52;
	--lightYellow: 						#FFE39A;
}

h1 {
	font-size: 							64px;
	font-weight: 						700;
}

h2 {
	font-size: 							45px;
	font-weight: 						700;
}

h3 {
	font-size: 							32px;
	font-weight: 						700;
}

h4 {
	font-size: 							23px;
	font-weight: 						500;
}

h5 {
	font-size: 							16px;
}

p{
	font-size: 							16px;
}

span{
	font-size: 							16px
}

h1, h2, h3, h4, h5, p, span, img, button, a{
	display: 							block;
	margin: 							0;
}

.btnBgBlueTextGradient{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 50px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.btnBgBlueTextGradient:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.btnBgWhiteTextBlack{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 35px;
	color: 								#000000;
	background-color: 					rgba(255, 255, 255, 0.6);
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.btnBgWhiteTextBlack:hover{
	color: 								#FFFFFF;
	background-color: 					var(--red);
}

.btnBgTextRed{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 50px;
	color: 								var(--red);
	background-color: 					transparent;
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.searchContainer{
	position: 							relative;
}

.searchContainer.darkMode > input {
	background: 						#131E29;
	color: 								white;
}

.searchContainer.darkMode > input::placeholder {
	color: 								white;
}

.searchContainer::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("./assets/images/search.svg") center center no-repeat;
	background-size: 					20px 20px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
	cursor: 							pointer;
}

.inputContainer, .passwordContainer{
	position: 							relative;
	display: 							flex;
	flex-flow: 							column wrap;
	justify-content: 					flex-end;
	transition: 						all 0.3s ease-in-out;
}

.inputContainer .label{
	width: 								100%;
	font-size: 							12px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.inputContainer .label label{
	font-weight: 						300;
	opacity: 							0.5;
	margin-left: 						5px;
}

.inputContainer .arrowOption{
	width: 								100%;
	height: 							39px;
	position: 							absolute;
	left: 								0;
	bottom: 							0;
	cursor: 							pointer;
}

.inputContainer .arrowOption::after{
	content: 							"";
	width: 								15px;
	height: 							15px;
	background:  						url("./assets/images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	position: 							absolute;
	right: 								10px;
	bottom: 							10px;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.inputContainer .arrowOption.active::after{
	transform: 							rotate(270deg);
}

.inputContainer .listOptions{
	width: 								100%;
	max-height: 						170px;
	overflow-y: 						auto;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							5px 0;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	z-index: 							999;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.inputContainer .listOptions.show{
	visibility: 						visible;
	opacity: 							1;
}

.inputContainer .listOptions .option{
	display: 							flex;
	align-items: 						center;
	font-size: 							12px;
	padding: 							0 20px;
	cursor: 							pointer;
}

.inputContainer .listOptions .option:hover{
	background: 						rgba(4, 26, 57, 0.03);
}

.inputContainer .listOptions .option > *{
	padding: 							5px 0;
}

.inputContainer .listOptions .option > label{
	width: 								100%;
	cursor: 							pointer;
}

.inputContainer.radio{
	flex-flow: 							row wrap;
	align-items: 						center;
	justify-content: 					flex-start;
}

.inputContainer.radio > label{
	cursor: 							pointer;
}

.inputContainer.radio > label:first-of-type{
	margin-right: 						30px;
}

.inputContainer.dollars > input{
	padding: 							10px 15px;
}

.inputContainer.dollars::before{
	content: 							"$";
	font-size: 							14px;
	color: 								rgba(0, 0, 0, 0.4);
	position: 							absolute;
	bottom: 							10px;
	left: 								5px;
	z-index: 							9;
}

.inputContainer.dollars::after{
	content: 							"USD";
	font-size: 							12px;
	color: 								rgba(0, 0, 0, 0.4);
	position: 							absolute;
	bottom: 							10px;
	right: 								10px;
	z-index: 							9;
}

.darkMode .inputContainer.dollars::before,
.darkMode .inputContainer.dollars::after{
	color: 								white;
}

.inputContainer .noteUser{
	font-size: 							10px;
	opacity: 							0.5;
	margin-top: 						0;
	max-height: 						0;
	overflow: 							hidden;
	transition: 						all 0.3s ease-in-out;
	display: 							flex;
	align-items: 						center;
}

.inputContainer .noteUser.show{
	max-height: 						1000px;
	margin-top: 						10px;
}

.inputContainer .noteUser > img{
	width: 								10px;
	height: 							10px;
	margin-right: 						5px;
}

.inputContainer.calendar::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("./assets//images/calendar.svg") center center no-repeat;
	background-size: 					20px 20px;
	position: 							absolute;
	bottom: 							10px;
	right: 								10px;
	pointer-events: 					none;
}

.dropdown{
	width: 								100%;
}

.dropdown-toggle{
	width: 								100%;
	font-size: 							12px;
	color: 								#000000;
	border: 							unset;
	border-radius: 						unset;
	padding: 							10px 30px 10px 10px;
	text-align: 						start;
	background-color: 					#FFFFFF;
	border-bottom: 						1px solid transparent;
	transition: 						all 0.3s ease-in-out;
	overflow: 							hidden;
	text-overflow: 						ellipsis;
}

.darkMode .dropdown-toggle{
	background-color: 					#27343F;
	color: 								white;
}

.dropdown-toggle.default{
	color: 								rgba(0, 0, 0, 0.4);
}

.darkMode .dropdown-toggle.default{
	color: 								white;
}

.dropdown-toggle.show, .dropdown-toggle:hover, .dropdown-toggle:focus, .dropdown-toggle:active, .btn:first-child:active{
	outline: 							unset;
	box-shadow: 						unset;
	background-color: 					#FFFFFF;
	color: 								#000000;
	border-bottom: 						1px solid #009AFF;
}

.darkMode .dropdown-toggle.show, .darkMode .dropdown-toggle:hover, .darkMode .dropdown-toggle:focus, .darkMode .dropdown-toggle:active, .darkMode .btn:first-child:active{
	background-color: 					#131E29;
	color: 								white;
}

.dropdown-toggle::after{
	border: 							unset;
	width: 								15px;
	height: 							15px;
	background:  						url("./assets/images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	position: 							absolute;
	right: 								10px;
	bottom: 							12px;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.dropdown-toggle.show::after{
	transform: 							rotate(270deg);
}

.dropdown-toggle.disabled{
	color: 								#000000;
    background-color: 					#FFFFFF;
    border: 							unset;
	opacity: 							0.5;
}

.dropdown:has(.dropdown-toggle.disabled):hover{
	cursor: 							not-allowed;
}

.dropdown-menu{
	min-width: 							100%;
	max-height: 						170px;
	padding: 							0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	border: 							unset;
	border-radius: 						unset;
	overflow-y: 						auto;
}

/* width */
.dropdown-menu::-webkit-scrollbar {
	width: 								3px;
	height: 							3px;
}
  
 /* Track */
.dropdown-menu::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.dropdown-menu::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.dropdown-item{
	font-size: 							12px;
	padding: 							5px 20px;
	transition: 						all 0.3s ease-in-out;
}

.darkMode .dropdown-item{
	background-color: 					#27343F;
	color: 								white;
}

.dropdown-menu .optionCategory{
	padding: 							5px 20px;
	display: 							flex;
	align-items: 						center;
}

.darkMode .dropdown-menu .optionCategory{
	background-color: 					#27343F;
	color: 								white;
}

.dropdown-menu .optionCategory label{
	font-size: 							12px;
	flex: 								1 0 0;
	cursor: 							pointer;
}

.tooltip .tooltip-inner{
	font-size: 							12px;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-weight: 						500;
	padding: 							10px;
}

.tooltip .tooltip-arrow::before{
	border-top: 						6px solid #FFFFFF;
}

input.disabled, .disabled{
	opacity: 							0.5;
}

input.disabled:hover, .disabled:hover{
	cursor: 							not-allowed;
}

input:not(input[type="checkbox"], input[type="radio"]),
textarea{
	width: 								100%;
	border: 							unset;
	background-color: 					#FFFFFF;
	padding: 							10px;
	font-size: 							12px;
	font-weight: 						300;
	position: 							relative;
	border-bottom: 						1px solid transparent;
	transition: 						all 0.3s ease-in-out;
	resize: 							none;
}

.darkMode input:not(input[type="checkbox"], .darkMode input[type="radio"]),
.darkMode textarea{
	background-color: 					#27343F;
	color: 								white;
	-webkit-text-fill-color: 			white;
}

input:not(input[type="checkbox"], input[type="radio"])::placeholder, 
textarea::placeholder{
	opacity: 							0.4;
}

input:not(input[type="checkbox"], input[type="radio"]):focus, 
textarea:focus{
	outline: 							unset;
	border-bottom: 						1px solid #009AFF;
}

input[type="file"]{
	background-color: 					transparent !important;
	padding: 							0 !important;
}

input[type="file"]::file-selector-button{
	padding: 							10px 15px;
	border: 							unset;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

input[type="file"]::file-selector-button:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
}

input[type="radio"],
input[type="checkbox"]{
	-webkit-appearance: 				none;
	appearance: 						none;
	width: 								20px;
	height: 							20px;
	border-radius: 						10px;
	border: 							1px solid rgba(0, 0, 0, 0.2);
	outline: 							none;
	cursor: 							pointer;
	margin-right: 						10px;
	transition: 						all 0.3s ease-in-out;
}

.darkMode input[type="radio"],
.darkMode input[type="checkbox"]{
	background-color: 					white;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked{
	background-color: 					var(--green) !important;
	border: 							1px solid transparent;
	position: 							relative;
}

input[type="radio"].no:checked,
input[type="checkbox"].no:checked{
	background-color: 					var(--red);
}

input[type="radio"]:checked::after,
input[type="checkbox"]:checked::after{
	content: 							"";
	width: 								14px;
	height: 							14px;
	background: 						url("./assets/images/checked.svg") no-repeat center center;
	background-size: 					14px;
	position: 							absolute;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	margin: 							auto;
}

input[type="radio"].no:checked::after,
input[type="checkbox"].no:checked::after{
	background: 						url("./assets/images/block.svg") no-repeat center center;
	background-size: 					14px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button{
	-webkit-appearance: 				none;
	margin: 							0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: 						transparent;
    bottom: 							0;
    color: 								transparent;
    cursor: 							pointer;
    height: 							auto;
    left: 								0;
    position: 							absolute;
    right: 								0;
    top: 								0;
    width: 								auto;
}

.passwordContainer .passwordIcon{
	width: 								25px;
	height: 							25px;
	background: 						url("./assets/images/visibilityOff.svg");
	background-size: 					25px;
	background-position: 				center center;
	cursor: 							pointer;
	position: 							absolute;
	right: 								10px;
	bottom: 							6px;
}

.passwordContainer .passwordIcon.show{
	background: 						url("./assets/images/visibility.svg");
	background-size: 					25px;
	background-position: 				center center;
}

.passwordContainer .passwordTooltip{
	width: 								fit-content;
	padding: 							10px 25px;
	color: 								rgba(0, 0, 0, 0.5);
	background-color: 					#FFFFFF;
	position: 							absolute;
	top: 								120%;
	right: 								-20%;
	opacity:							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
}

.passwordContainer .passwordTooltip.show{
	opacity: 							1;
	visibility: 						visible;
}

.passwordContainer .passwordTooltip::before{
	content: 							"";
	width: 								0;
	height: 							0;
	border-left: 						10px solid transparent;
	border-right: 						10px solid transparent;
	border-bottom: 						10px solid #FFFFFF;
	position: 							absolute;
	left: 								0;
	right: 								0;
	bottom: 							100%;
	margin: 							0 auto;
}

table{
	width: 								100%;
	table-layout: 						fixed;
}

table thead{
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.darkMode table thead tr{
	background: 						#27343F;
}

table thead th{
	padding: 							20px;
	transition: 						background 0.3s ease-in-out;
}

table thead th > span{
	display: 							inline-flex;
	align-items: 						center;
	font-size: 							12px;
}

table thead th .sort{
	display: 							inline-flex;
	align-items: 						center;
	width: 								15px;
	height: 							15px;
	margin-left: 						5px;
	cursor: 							pointer;
}

table tbody tr{
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
}

.darkMode table tbody tr{
	border-bottom: 						1px solid #27343F;
}

table tbody td{
	position: 							relative;
	padding: 							15px 20px;
}

table tbody td .infoContainer {
	position: 							relative;
	display: 							flex;
	align-items: 						center;
}

table tbody td .infoContainer > span {
	display: 							inline-flex;
	align-items: 						center;
	font-weight: 						500;
	font-size: 							12px;
	opacity: 							0.5;
}

.success, .successAlone{
	color: 								var(--green);
	opacity: 							1 !important;
}

.uploadingDocs, .uploadingDocsAlone {
	color: 								var(--yellow);
	opacity: 							1 !important;
}

.success::after, .new::after, .pending::after, .uploadingDocs::after{
	content: 							"\025cf";
	margin-left: 						5px;
}

.failed, .failedAlone{
	color: 								var(--red);
	opacity: 							1 !important;
}

.failed::after{
	content: 							"";
	display: 							inline-flex;
	width: 								15px;
	height: 							15px;
	background: 						url("./assets/images/error.svg") center center no-repeat;
	background-size: 					15px 15px;
	margin-left: 						5px;
}

.new, .newAlone{
	color: 								#00A3FF;
	opacity: 							1 !important;
}

.pending, .pendingAlone{
	color: 								var(--orange);
	opacity: 							1 !important;
}

.hideElement{
	opacity: 							0;
	visibility: 						hidden;
}

bg-grey{
	font-size: 							12px;
	opacity: 							0.5;
}

.nav-tabs{
	border: 							unset;
	gap: 								10px;
	margin-bottom: 						10px;
}

.nav-tabs .nav-link{
	padding: 							10px 20px;
	border-radius: 						unset;
	border: 							unset;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-size: 							12px;
}
.darkMode .nav-tabs .nav-link{
	background-color: 					#131E29;
	color: 								white;
	font-size: 							12px;
}

.nav-tabs .nav-link.active{
	font-weight: 						700;
	color: 								#FFFFFF;
	background-color: 					var(--darkBlue);
}

.nav-tabs.darkMode .nav-link.active{
	background-color: 					#243D55;
}

/* SWEET ALERT MODALS */
/* BACKDROP */
.changeBackdrop, .modal-backdrop{
	background: 						rgba(255, 255, 255, 0.35) !important;
    backdrop-filter: 					blur(2px);
}

.swal2-popup{
	border-radius: 						0;
}

.swal2-confirm{
	background: 						var(--gradient), var(--blue) !important;
	background-clip: 					text, padding-box !important;
	-webkit-background-clip: 			text, padding-box !important;
	-webkit-text-fill-color: 			transparent !important;
	border-radius: 						unset !important;
	padding:  							10px 20px !important;
	transition: 						all 0.3s ease-in-out !important;
}

.swal2-confirm:hover{
	background-color: 					var(--darkBlue) !important;
}

.swal2-confirm:focus{
	box-shadow: 						unset !important;
}

.swal2-cancel{
	color: 								var(--grey) !important;
	background-color: 					var(--red) !important;
	border-radius: 						unset !important;
	padding:  							10px 20px !important;
	transition: 						all 0.3s ease-in-out !important;
}

.swal2-cancel:hover{
	background-color: 					var(--darkBlue) !important;
}

.swal2-timer-progress-bar-container{
	border-radius: 						0px !important;
}

.swal2-timer-progress-bar{
	background: 						var(--gradient);
}

/* MODAL */
.modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.modal-body .closeModal,
.closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

.changeModal{
	width: 								fit-content;
	padding: 							100px 200px;
	opacity: 							0.92;
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.20);
	border-radius: 						unset;
}

/* FOOTER */
.changeFooter{
	font-size: 							16px;
	margin: 							15px 0 0;
	padding: 							0;
	border-top: 						unset;
}

@media (max-width: 991px){
	.btnBgBlueTextGradient,
	.btnBgWhiteTextBlack{
		font-size: 							12px;
		padding: 							10px 30px;
	}
	
	.searchContainer::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("./assets/images/search.svg") center center no-repeat;
		background-size: 					20px 20px;
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		right: 								10px;
		margin: 							auto 0;
		cursor: 							pointer;
	}
	
	.inputContainer .label{
		margin-bottom: 						10px;
	}
	
	.inputContainer .label label{
		font-weight: 						300;
		opacity: 							0.5;
		margin-left: 						5px;
	}
	
	.inputContainer .arrowOption{
		width: 								100%;
		height: 							39px;
		position: 							absolute;
		left: 								0;
		bottom: 							0;
		cursor: 							pointer;
	}
	
	.inputContainer .arrowOption::after{
		content: 							"";
		width: 								15px;
		height: 							15px;
		background:  						url("./assets/images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
		position: 							absolute;
		right: 								10px;
		bottom: 							10px;
		cursor: 							pointer;
		transition: 						all 0.3s ease-in-out;
	}
	
	.inputContainer .arrowOption.active::after{
		transform: 							rotate(270deg);
	}
	
	.inputContainer .listOptions{
		width: 								100%;
		max-height: 						170px;
		overflow-y: 						auto;
		background: 						#FFFFFF;
		box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
		padding: 							5px 0;
		position: 							absolute;
		top: 								105%;
		left: 								0;
		z-index: 							999;
		visibility: 						hidden;
		opacity: 							0;
		transition: 						all 0.3s ease-in-out;
	}
	
	.inputContainer .listOptions.show{
		visibility: 						visible;
		opacity: 							1;
	}
	
	.inputContainer .listOptions .option{
		display: 							flex;
		align-items: 						center;
		font-size: 							12px;
		padding: 							0 20px;
		cursor: 							pointer;
	}
	
	.inputContainer .listOptions .option:hover{
		background: 						rgba(4, 26, 57, 0.03);
	}
	
	.inputContainer .listOptions .option > *{
		padding: 							5px 0;
	}
	
	.inputContainer .listOptions .option > label{
		width: 								100%;
		cursor: 							pointer;
	}
	
	.inputContainer.radio{
		flex-flow: 							row wrap;
		align-items: 						center;
		justify-content: 					flex-start;
	}
	
	.inputContainer.radio > label{
		cursor: 							pointer;
	}
	
	.inputContainer.radio > label:first-of-type{
		margin-right: 						30px;
	}
	
	.inputContainer .noteUser{
		font-size: 							10px;
		opacity: 							0.5;
		margin-top: 						0;
		max-height: 						0;
		overflow: 							hidden;
		transition: 						all 0.3s ease-in-out;
		display: 							flex;
		align-items: 						center;
	}
	
	.inputContainer .noteUser.show{
		max-height: 						1000px;
		margin-top: 						10px;
	}
	
	.inputContainer .noteUser > img{
		width: 								10px;
		height: 							10px;
		margin-right: 						5px;
	}

	.inputContainer.calendar::after{
		content: 							"";
		width: 								15px;
		height: 							15px;
		background: 						url("./assets//images/calendar.svg") center center no-repeat;
		background-size: 					15px 15px;
		position: 							absolute;
		bottom: 							12px;
		right: 								10px;
		pointer-events: 					none;
	}
	
	.dropdown-toggle.default{
		color: 								rgba(0, 0, 0, 0.4);
	}
	
	.dropdown-toggle.show, .dropdown-toggle:hover, .dropdown-toggle:focus, .dropdown-toggle:active{
		outline: 							unset;
		box-shadow: 						unset;
		background-color: 					#FFFFFF;
		color: 								#000000;
		border-bottom: 						1px solid #009AFF;
	}
	
	.dropdown-toggle::after{
		border: 							unset;
		width: 								15px;
		height: 							15px;
		background:  						url("./assets/images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
		position: 							absolute;
		right: 								10px;
		bottom: 							10px;
		cursor: 							pointer;
		transition: 						all 0.3s ease-in-out;
	}
	
	.dropdown-toggle.show::after{
		transform: 							rotate(270deg);
	}
	
	.dropdown-menu{
		width: 								100%;
		max-height: 						170px;
		padding: 							0;
		background: 						#FFFFFF;
		box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
		border: 							unset;
		border-radius: 						unset;
		overflow-y: 						auto;
	}
	
	/* width */
	.dropdown-menu::-webkit-scrollbar {
		width: 								3px;
	}
	  
	 /* Track */
	.dropdown-menu::-webkit-scrollbar-track {
		background: 						rgba(0, 0, 0, 0.05);
	 }
	  
	/* Handle */
	.dropdown-menu::-webkit-scrollbar-thumb {
		background: 						var(--darkBlue);
	}
	
	.dropdown-item{
		font-size: 							12px;
		padding: 							5px 20px;
		transition: 						all 0.3s ease-in-out;
	}
	
	.dropdown-item.radio{
		display: 							flex;
		align-items: 						center;
		pointer-events: 					none;
	}
	
	input:not(input[type="checkbox"], input[type="radio"]),
	textarea{
		width: 								100%;
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		font-weight: 						300;
		position: 							relative;
		border-bottom: 						1px solid transparent;
		transition: 						all 0.3s ease-in-out;
		resize: 							none;
	}
	
	input:not(input[type="checkbox"], input[type="radio"])::placeholder, 
	textarea::placeholder{
		opacity: 							0.4;
	}
	
	input:not(input[type="checkbox"], input[type="radio"]):focus, 
	textarea:focus{
		outline: 							unset;
		border-bottom: 						1px solid #009AFF;
	}
	
	input[type="file"]{
		background-color: 					transparent !important;
		padding: 							0 !important;
	}
	
	input[type="file"]::file-selector-button{
		padding: 							10px 15px;
		border: 							unset;
		background: 						var(--gradient), var(--blue);
		background-clip: 					text, padding-box;
		-webkit-background-clip: 			text, padding-box;
		-webkit-text-fill-color: 			transparent;
		cursor: 							pointer;
		transition: 						all 0.3s ease-in-out;
	}
	
	input[type="file"]::file-selector-button:hover{
		background: 						var(--gradient), var(--darkBlue);
		background-clip: 					text, padding-box;
		-webkit-background-clip: 			text, padding-box;
	}
	
	input[type="radio"],
	input[type="checkbox"]{
		-webkit-appearance: 				none;
		appearance: 						none;
		width: 								20px;
		height: 							20px;
		border-radius: 						10px;
		border: 							1px solid rgba(0, 0, 0, 0.2);
		outline: 							none;
		cursor: 							pointer;
		margin-right: 						10px;
		transition: 						all 0.3s ease-in-out;
	}
	
	input[type="radio"]:checked,
	input[type="checkbox"]:checked{
		background-color: 					var(--green);
		border: 							1px solid transparent;
		position: 							relative;
	}
	
	input[type="radio"].no:checked,
	input[type="checkbox"].no:checked{
		background-color: 					var(--red);
	}
	
	input[type="radio"]:checked::after,
	input[type="checkbox"]:checked::after{
		content: 							"";
		width: 								14px;
		height: 							14px;
		background: 						url("./assets/images/checked.svg") no-repeat center center;
		background-size: 					14px;
		position: 							absolute;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
	}
	
	input[type="radio"].no:checked::after,
	input[type="checkbox"].no:checked::after{
		background: 						url("./assets/images/block.svg") no-repeat center center;
		background-size: 					14px;
	}
	
	.passwordContainer .passwordIcon{
		width: 								20px;
		height: 							20px;
		background-size: 					20px;
		right: 								10px;
		bottom: 							8px;
	}
	
	.passwordContainer .passwordIcon.show{
		background-size: 					20px;
	}
	
	.passwordContainer .passwordTooltip{
		width: 								fit-content;
		padding: 							10px 15px;
		right: 								0%;
	}
	
	.passwordContainer .passwordTooltip::before{
		left: 								unset;
		right: 								10%;
		margin: 							0;
	}

	.failed::after{
		width: 								10px;
		height: 							10px;
		background-size: 					10px 10px;
	}

	.nav-tabs{
		padding-top: 						30px;
		padding-bottom: 					10px;
		margin-bottom: 						0;
		gap: 								5px;
		flex-flow: 							row nowrap;
		overflow-x: 						auto;
		overflow-y: 						hidden;
	}

	.nav-tabs .nav-link{
		padding: 							10px;
		font-size: 							10px;
		width: 								max-content;
	}
}